import React from 'react'
import { Container } from 'react-bootstrap'
import partners from '../images/partners.png'

function Partners() {
  return (
    <div className="margin-t">
      <div className="text-center my-5">
        <h2 className="titulo-noticias">GLOBAL PARTNERS</h2>
        <p className="parrafo-planes">Global partners are with Genea</p>
      </div>
      <Container className="d-flex justify-content-center">
        <img src={partners} alt="partners" className="img-fluid" />
      </Container>
    </div>
  )
}

export default Partners
