import React, { useEffect, useState } from "react";
import NavBarGeneral from "./NavBarGeneral";
import "../index.css";
import "../Resultados.css";
import "../Contacto.css";
import resultado from "../images/resultado1.jpg";
import ResultadoBannerMovil from "../images/ResultadoBannerMovil.png";
import ImagenResultados from "../images/ImagenResultados.png";
import ImagenResultadosMovil from "../images/ImagenResultadosMovil.svg";
import notificaciones from "../images/notificacion.png";
import { Button, Form } from "react-bootstrap";
import Footer from "./Footer";
import FooterMovil from "./FooterMovil";
import Imgcontacto from "../images/Contactoprinci.png";
import Imgcontacto1 from "../images/doctoracontacto.png"
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
function Contacto(props) {
  const [showFooter, setShowFooter] = useState(true);
  const [showBanner, setShowBanner] = useState(window.innerWidth >= 1038);
  const [showImagen, setShowImagen] = useState(window.innerWidth > 1342);
  const [Tamaño01, setTamaño01] = useState(window.innerWidth > 703);
  const [Tamaño02, setTamaño02] = useState(window.innerWidth > 453);
  useEffect(() => {
    const handleResize = () => {
      /* MUESTRA EL BANNER PEQUEÑO */
      if (window.innerWidth <= 550) {
        setShowFooter(false);
      } else {
        /* MUESTRA EL BANNER GRANDE */
        setShowFooter(true);
      }
    };


    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setShowBanner(window.innerWidth >= 1038);
      setShowImagen(window.innerWidth > 1342);
      setTamaño01(window.innerWidth > 703);
      setTamaño02(window.innerWidth > 453);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePlanesClick = () => {
    window.location.href = "/#planes";
  };

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setShowBanner(window.innerWidth >= 1038);
    setShowImagen(window.innerWidth > 1342);
    setTamaño01(window.innerWidth > 703);
    setTamaño02(window.innerWidth > 453);
  }, []);

  const showAlert = () => {
    Swal.fire({
      title: '¡EXCELENTE!',
      text: 'Correo enviado correctamente',
      icon: 'success',
      confirmButtonText: "Ok",
      showCloseButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          setCorreo("");
          setNombre("");
          setTelefono("");
          setMensaje("");
          setCondiciones(false);
          setPolitica(false);
        }
      });
  }


  const showAlert1 = () => {
    Swal.fire({
      title: '¡UPS!',
      text: 'Hubo un error, inténtelo de nuevo',
      icon: 'error',
      confirmButtonText: "Ok",
      showCloseButton: true,
    })
  }


  const navigate = useNavigate()
  const [nombre, setNombre] = useState('')
  const [telefono, setTelefono] = useState('')
  const [correo, setCorreo] = useState('')
  const [mensaje, setMensaje] = useState('')
  const [condiciones, setCondiciones] = useState(false)
  const [politica, setPolitica] = useState(false)
  const handleSubmit = (e) => {
    e.preventDefault()


    // Crear objeto FormData para enviar los datos y la imagen
    const formData = new FormData()
    formData.append('nombre', nombre)
    formData.append('telefono', telefono)
    formData.append('correo', correo)
    formData.append('mensaje', mensaje)
    formData.append('politica', politica)
    formData.append('condiciones', condiciones)



    // Realizar la petición POST al backend para insertar el producto
    fetch(process.env.REACT_APP_API_URL + 'contactoCorreo.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //  Mostrar mensaje de éxito o error según la respuesta del backend
        if (data.success) {
          showAlert()
        } else if (!data.success) {
          showAlert1()
        }
      })
      .catch((error) => {
        console.error('Error:', error)

      })
  }



  return (
    <div id="contacto">
      <NavBarGeneral />
      <div className="flex flex-nowrap flex-column">
        {/* IMAGEN BANNER PC O MOVIL */}
        {showBanner ? (
          <div>
            <div style={{ paddingTop: "70px" }} className="">
              <img src={Imgcontacto} alt="" style={{ width: "100%", height: "500px" }} />
            </div>
          </div>
        ) : (
          <div className="">
            <div style={{ paddingTop: "58px" }} className="">
              <img
                src={ResultadoBannerMovil}
                alt=""
                className="img-fluid"
                style={{ width: "1034px", height: "500px" }}
              />
            </div>
          </div>
        )}

        <div className=" flex ResultadoContenedor2 flex-wrap flex-row" style={{ marginBottom: "60px", padding: "0px 20px" }}>
          <div className="flex flex-column flex-nowrap">
            {/* TEXTO */}
            <div className="flex flex-wrap flex-column ContenedorTextoContacto">
              <p
                className={showBanner ? "contactoaten" : "contactoaten01"}
              >
                ¡Estamos listos para <br />{" "}
                <b style={{ color: "#6CCA90" }}>atenderte!</b>
              </p>
              {showBanner ? (
                <p
                  className="contactoatentext1"
                >
                  Nuestra máxima prioridad es brindar un excelente servicio
                  <br />
                  personalizado a nuestros clientes.
                  <br /> Estamos disponibles por teléfono o correo electrónico de
                  <br /> lunes a viernes de <span className="nas">9:00 a. m. a 6:00 p. m.</span>
                </p>
              ) : null}


              <div
                className="flex flex-column gap-2"
                style={{ marginTop: "100px", color: "#1C3149" }}
              >
                <div className="flex gap-3 " >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1C3149" />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M32.2847 12.7058C29.8876 10.3173 26.6996 9.0014 23.3033 9C16.305 9 10.6093 14.6682 10.6065 21.635C10.6056 23.862 11.1901 26.036 12.3013 27.9522L10.5 34.5L17.2308 32.7428C19.0854 33.7497 21.1733 34.2802 23.2982 34.2809H23.3035C30.3011 34.2809 35.9973 28.6122 36 21.6451C36.0014 18.2687 34.682 15.0941 32.2847 12.7058ZM23.3033 32.1469H23.2989C21.4054 32.1461 19.5483 31.6397 17.9278 30.6829L17.5426 30.4552L13.5485 31.498L14.6146 27.6224L14.3636 27.2251C13.3072 25.5529 12.7493 23.6202 12.7503 21.6358C12.7525 15.8452 17.4866 11.1341 23.3076 11.1341C26.1263 11.1351 28.776 12.2289 30.7684 14.2141C32.7607 16.1993 33.8573 18.838 33.8564 21.6443C33.8539 27.4354 29.12 32.1469 23.3033 32.1469ZM29.0919 24.281C28.7747 24.1229 27.2149 23.3593 26.924 23.2538C26.6335 23.1484 26.4217 23.096 26.2104 23.4119C25.9988 23.7279 25.391 24.4391 25.2058 24.6497C25.0206 24.8605 24.8358 24.8869 24.5185 24.7288C24.2012 24.5708 23.179 24.2373 21.9671 23.1617C21.0241 22.3245 20.3875 21.2906 20.2023 20.9746C20.0175 20.6583 20.2007 20.5039 20.3415 20.3303C20.6849 19.9058 21.0288 19.4609 21.1345 19.2503C21.2404 19.0395 21.1874 18.8551 21.108 18.6971C21.0288 18.5392 20.3944 16.9851 20.1301 16.3527C19.8723 15.7373 19.611 15.8204 19.4161 15.8108C19.2313 15.8016 19.0197 15.7997 18.8081 15.7997C18.5967 15.7997 18.2529 15.8786 17.9621 16.1949C17.6713 16.511 16.8519 17.2747 16.8519 18.8288C16.8519 20.3829 17.9886 21.8842 18.1472 22.0949C18.3058 22.3056 20.3843 25.4947 23.5667 26.8621C24.3236 27.1877 24.9144 27.3818 25.3753 27.5273C26.1354 27.7677 26.8267 27.7337 27.3735 27.6525C27.9831 27.5617 29.2503 26.8886 29.5149 26.1512C29.7792 25.4136 29.7792 24.7815 29.6998 24.6497C29.6206 24.5181 29.409 24.4391 29.0919 24.281Z"
                      fill="white"
                    />
                  </svg>
                  <p style={{ marginTop: "4px" }}>
                    +51 960 645 355
                  </p>
                </div>

                <div className="flex gap-3 " >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1C3149" />
                    <g clip-path="url(#clip0_821_21)">
                      <path
                        d="M22.4996 25.0154C18.9189 25.0154 16.0059 22.1023 16.0059 18.5216C16.0059 14.9136 18.9189 11.9783 22.4996 11.9783C26.0803 11.9783 28.9934 14.9136 28.9934 18.5216C28.9934 22.1023 26.0803 25.0154 22.4996 25.0154ZM22.4996 13.0525C19.5113 13.0525 17.0801 15.5059 17.0801 18.5216C17.0801 21.51 19.5113 23.9412 22.4996 23.9412C25.4879 23.9412 27.9191 21.51 27.9191 18.5216C27.9191 15.5059 25.4879 13.0525 22.4996 13.0525Z"
                        fill="#71CC96"
                      />
                      <path
                        d="M21.7552 21.2926C21.6177 21.2926 21.4803 21.2401 21.3754 21.1353L19.1417 18.9015C18.9319 18.6918 18.9319 18.3517 19.1417 18.1419C19.3514 17.9322 19.6915 17.9322 19.9013 18.1419L21.7552 19.9959L24.8345 16.9166C25.0443 16.7068 25.3843 16.7068 25.5942 16.9166C25.8039 17.1263 25.8039 17.4664 25.5942 17.6762L22.135 21.1353C22.0301 21.2401 21.8926 21.2926 21.7552 21.2926Z"
                        fill="#71CC96"
                      />
                      <path
                        d="M34.9992 19.9814C34.9907 19.8321 34.9178 19.6926 34.8037 19.5957L31.9722 17.1587V9.53711C31.9722 9.24023 31.7314 9 31.4351 9H13.5649C13.2686 9 13.0278 9.24023 13.0278 9.53711V17.1587L10.1865 19.604C10.0684 19.7056 10 19.8545 10 20.0107V33.4629C10 33.7598 10.2402 34 10.5371 34C10.5371 34 34.46 34 34.4629 34C34.6401 34 34.8149 33.9058 34.9121 33.7573C34.9688 33.6704 35 33.5669 35 33.4629C35 33.4629 35 20.0111 35 20.0107C35 20.0009 34.9997 19.9912 34.9992 19.9814ZM33.6421 20.0132L31.9722 21.4683V18.5762L33.6421 20.0132ZM14.1021 10.0742H30.8979V22.4043L22.5 29.7227L14.1021 22.4048V10.0742ZM13.0278 18.5762V21.4688L11.3579 20.0132L13.0278 18.5762ZM11.0742 21.1914L16.939 26.3018L11.0742 32.166V21.1914ZM11.834 32.9258L17.7505 27.0093L22.147 30.8398C22.248 30.9277 22.374 30.9722 22.5 30.9722C22.626 30.9722 22.752 30.9277 22.853 30.8398L27.2495 27.0088L33.166 32.9258H11.834ZM33.9258 32.166L28.061 26.3018L33.9258 21.1914V32.166Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_821_21">
                        <rect
                          width="25"
                          height="25"
                          fill="white"
                          transform="translate(10 9)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p style={{ marginTop: "4px" }}>
                    consultas@genea.com.pe
                  </p>
                </div>


                <div className="flex gap-3" >
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="22.5" cy="22.5" r="22.5" fill="#1C3149" />
                    <g clip-path="url(#clip0_821_22)">
                      <path
                        d="M27.9806 36C18.5974 35.891 6.84615 23.8394 9.37079 14.3506C9.88222 11.5689 14.814 6.76974 18.0208 10.1768C19.2958 11.5276 20.9377 12.5795 20.8955 14.6682C20.9047 15.9859 20.2754 17.0047 19.3223 17.858C18.2432 18.9372 18.1433 20.6538 19.0901 21.8508C20.2558 23.3261 21.6503 24.7204 23.1257 25.8865C24.3228 26.8332 26.0393 26.7334 27.1185 25.6542L27.5553 25.2175C28.2881 24.4847 29.2658 24.081 30.3084 24.081C32.3946 24.0374 33.4529 25.684 34.7999 26.9558C38.2018 30.1913 33.4197 35.0756 30.626 35.6058C29.8111 35.869 28.924 36 27.9806 36ZM15.3619 10.1594C13.4171 10.083 10.8232 12.9057 10.5094 14.575C8.46584 22.3539 16.601 31.3046 23.5997 33.953C27.0069 35.1714 30.1317 35.3439 32.7044 33.1802C34.4194 31.712 35.7431 29.5352 33.9797 27.776L32.2413 26.0377C31.2298 24.9824 29.3868 24.9827 28.3755 26.0378L27.9387 26.4745C26.4434 27.9699 24.0648 28.1082 22.4061 26.7964C21.6335 26.1853 20.8816 25.5153 20.1715 24.8051C19.461 24.0946 18.791 23.3427 18.1802 22.5704C16.8684 20.9118 17.0068 18.5333 18.5021 17.0379L18.9389 16.6011C19.9942 15.5904 19.9939 13.7461 18.9389 12.7353L17.2005 10.997C16.6408 10.4372 16.0337 10.1594 15.3619 10.1594Z"
                        fill="white"
                      />
                      <path
                        d="M30.7297 19.7458C30.4621 19.7458 30.2216 19.5595 30.1632 19.2873C29.9272 18.1873 29.3821 17.1855 28.5866 16.3901C27.7792 15.5826 26.7612 15.034 25.6428 14.8036C25.3291 14.739 25.1271 14.4323 25.1917 14.1186C25.2564 13.8049 25.5631 13.6029 25.8768 13.6676C27.218 13.9438 28.4386 14.6016 29.4068 15.5699C30.3606 16.5237 31.0144 17.725 31.2973 19.0441C31.3645 19.3572 31.1651 19.6656 30.8519 19.7328C30.811 19.7415 30.77 19.7458 30.7297 19.7458Z"
                        fill="#71CC96"
                      />
                      <path
                        d="M34.0053 23.0043C33.9674 23.0043 33.929 23.0006 33.8905 22.9928C33.5765 22.9298 33.373 22.6241 33.4361 22.3101C34.0402 19.3015 33.1054 16.21 30.9356 14.0402C28.7619 11.8664 25.6652 10.9326 22.6521 11.5425C22.3381 11.6063 22.0322 11.403 21.9687 11.089C21.9051 10.7751 22.1081 10.4691 22.422 10.4056C25.8173 9.71836 29.3065 10.7705 31.7559 13.2199C34.2007 15.6649 35.254 19.1484 34.5733 22.5383C34.518 22.8139 34.2759 23.0043 34.0053 23.0043Z"
                        fill="#71CC96"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_821_22">
                        <rect
                          width="27"
                          height="27"
                          fill="white"
                          transform="translate(9 9)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p style={{ marginTop: "4px" }}>
                    (01) 652 6328
                  </p>
                </div>
                <p className="contenedorllenaForm">
                  Llena el formulario y nuestro equipo respondera <br /> a la
                  brevedad.
                </p>
              </div>
            </div>
          </div>
          <div className="flex ContenedorDelContenedor flex-nowrap flex-column round">
            {/* TITULO BIENVENIDO A RESULTADOS EN LINEA */}

            {/* FORMULARIO */}
            <div className="contacto1 bg-white round">
              <Form onSubmit={handleSubmit}>
                <Form.Group className="titulocontac">
                  <Form.Label> Déjanos tu mensaje</Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Label className="label-css font-weight-bold">
                    Nombre y Apellido :
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu nombre completo"
                    required
                    name="Nombre"
                    className="custom-input"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="validationCustom03">
                  <Form.Label className="label-css">Teléfono:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Ingresa tu telefono"
                    required
                    name="Telefono"
                    className="custom-input"
                    value={telefono}
                    onChange={(e) => setTelefono(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="validationCustom02">
                  <Form.Label className="label-css">
                    Correo electrónico:
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Ingresa tu correo"
                    required
                    name="Email"
                    className="custom-input"
                    value={correo}
                    onChange={(e) => setCorreo(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="validationCustom01">
                  <Form.Label className="label-css font-weight-bold">
                    Mensaje :
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    placeholder="Escribe tu mensaje"
                    required
                    name="mensaje"
                    className="custom-input"
                    style={{
                      height: "100px",
                      lineHeight: "normal",
                      resize: "none",
                      overflowY: "auto",
                    }}
                    value={mensaje}
                    onChange={(e) => setMensaje(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="validationCustom04">
                  <Form.Check
                    type="checkbox"
                    label="He leido y aceptado las condiciones establecidas en la clausula de proteccion de datos personales "
                    className="fuente-check"
                    name="Checkbox"
                    checked={condiciones}
                    onChange={(e) => setCondiciones(e.target.checked)}

                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="validationCustom04">
                  <Form.Check
                    type="checkbox"
                    label="He leido y aceptado las condiciones establecidas en la politica de cookies"
                    className="fuente-check"
                    name="Checkbox"
                    checked={politica}
                    onChange={(e) => setPolitica(e.target.checked)}

                  />
                </Form.Group>
                <Form.Group>
                  <div className="d-flex justify-content-center">
                    <div className="mx-auto">
                      <button type="submit" className="boton" >
                        Aceptar
                      </button>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>

        <div className="flex justify-content-center flex-wrap" style={{ background: "#F8F8F8", paddingTop: "70px", padding: "0px 20px" }}>

          <div className="flex flex-column">
            <div className="">
              <svg width="50" height="50" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="40" cy="40" r="40" fill="#1C3149" />
                <path d="M48.6536 65.9999C44.4039 62.4216 40.2857 58.6772 36.4997 54.6074C34.4164 52.2957 32.2916 49.9147 30.9765 47.0631C30.5543 46.108 30.2013 45.1252 29.9176 44.1216C29.1285 41.4361 29.1354 38.5568 29.9314 35.8783C30.3051 34.5355 30.7966 33.2274 31.4126 31.9746C32.0701 30.7426 32.873 29.6006 33.7243 28.507C38.0847 23.0946 43.3242 18.4503 48.6467 14.0068C47.6154 14.8997 46.5218 15.8064 45.4906 16.7338C41.3516 20.4229 37.268 24.2366 33.8004 28.5763C32.9491 29.6698 32.1462 30.8049 31.4956 32.03C30.8796 33.2689 30.3882 34.577 30.0145 35.9059C29.2185 38.5707 29.2047 41.4292 29.9798 44.1008C30.2567 45.1044 30.6097 46.0872 31.025 47.0355C32.3331 49.8801 34.451 52.2611 36.5205 54.5728C40.2788 58.6564 44.4316 62.4147 48.6328 65.9999" fill="white" />
                <path d="M32.0568 14C36.3065 17.5783 40.4247 21.3228 44.2107 25.3925C46.294 27.7042 48.4188 30.0852 49.727 32.9368C50.1423 33.8919 50.4953 34.8817 50.7721 35.8853C51.5542 38.5638 51.6027 41.45 50.786 44.1286C50.3984 45.4644 49.9069 46.7725 49.284 48.0184C48.6265 49.2504 47.8167 50.3855 46.9654 51.486C42.598 56.8984 37.3655 61.5496 32.0499 66C33.0742 65.1002 34.1678 64.2005 35.1991 63.273C39.3381 59.5839 43.4147 55.7633 46.8823 51.4237C47.7336 50.3301 48.5434 49.195 49.194 47.9768C49.81 46.7379 50.3084 45.4367 50.696 44.1078C51.5196 41.4431 51.4712 38.5846 50.696 35.913C50.4191 34.9094 50.073 33.9265 49.6578 32.9714C48.3566 30.1267 46.2386 27.7458 44.1622 25.434C40.397 21.3505 36.2442 17.5922 32.043 14.0069" fill="white" />
                <path d="M37.8214 21.7795C38.1813 21.4473 38.7142 21.2328 39.1918 21.3573C39.4202 21.4335 39.6486 21.6204 39.877 21.655C40.1054 21.6965 40.3338 21.6342 40.5622 21.5235C40.9844 21.3158 41.4689 21.212 41.9327 21.2258C42.4448 21.2812 42.8809 21.4335 43.3031 21.7865V21.9041C42.8809 22.2502 42.4448 22.4025 41.9327 22.4648C41.5866 22.4786 41.2405 22.4025 40.9014 22.3125C40.5761 22.1879 40.2231 21.9595 39.8701 22.0356C39.6417 22.0702 39.4133 22.2571 39.1849 22.3332C38.9565 22.4094 38.7281 22.3609 38.4997 22.2986C38.2436 22.2225 38.0221 22.1118 37.8145 21.9041V21.7865L37.8214 21.7795Z" fill="white" />
                <path d="M42.6785 58.5179C42.3947 58.8363 41.931 59.0647 41.5157 58.9401C41.3219 58.864 41.1281 58.6771 40.9343 58.6425C40.7405 58.601 40.5467 58.6632 40.3529 58.774C40.0068 58.9816 39.5916 59.0854 39.1901 59.0716C38.7402 59.0093 38.3803 58.8501 38.0273 58.511V58.3933C38.3803 58.0542 38.7402 57.895 39.1901 57.8327C39.4808 57.8188 39.7784 57.895 40.0622 57.985C40.3321 58.1026 40.6367 58.3379 40.9343 58.2618C41.2181 58.1995 41.4811 57.8673 41.8064 57.9365C42.1455 57.978 42.4362 58.1234 42.6785 58.3933V58.511V58.5179Z" fill="white" />
                <path d="M45.7185 39.8441C43.7113 39.3665 37.7174 39.4496 35.115 39.3596C32.5126 39.2696 30.4846 38.7782 29.4395 38.709C28.3875 38.6398 27.8545 39.2143 28.0345 40.0033C28.2144 40.7923 29.1142 41 30.187 40.9861C31.2667 40.9723 35.3019 40.4324 37.2745 40.4324C39.247 40.4324 47.9679 40.384 45.7254 39.8441" fill="white" />
                <path d="M52.2949 39.5812C52.3641 40.1626 51.7551 40.6609 51.1252 40.6609C50.4954 40.6609 49.6025 40.0242 49.1942 39.8512C48.7927 39.6781 50.1978 39.2767 50.7376 39.0483C51.2844 38.8199 52.198 38.8199 52.288 39.5881" fill="white" />
                <path d="M39.0116 33.1236C36.3676 33.1097 34.1251 33.0336 32.7824 32.8536C31.4396 32.6737 29.9031 32.3138 29.3702 33.1236C28.8372 33.9334 29.5501 34.6601 30.3391 34.7432C31.1282 34.8262 32.7755 34.1549 34.1044 34.1133C35.4332 34.0649 39.5168 33.8918 39.8767 33.6911C40.2367 33.4904 40.2782 33.1374 39.0254 33.1305" fill="white" />
                <path d="M42.6035 33.2484C42.0152 33.3868 41.2954 33.4629 41.7037 33.809C42.1121 34.1551 44.0847 34.0997 45.4759 34.0997C46.8671 34.0997 48.189 33.8921 49.1234 34.1551C50.2931 34.4804 51.4075 34.3489 51.3521 33.5252C51.2967 32.7016 50.5008 32.4386 49.1234 32.8608C47.663 33.3107 43.268 33.0892 42.6035 33.2484Z" fill="white" />
                <path d="M35.5788 26.6869C35.1151 26.5346 34.0769 26.3962 33.8139 27.026C33.5439 27.6559 34.0561 28.2373 34.6998 28.2373C35.3435 28.2373 36.4717 27.5797 37.4199 27.5659C38.3681 27.552 38.2574 26.7561 36.4232 26.8461C35.911 26.8738 35.5927 26.6869 35.5927 26.6869" fill="white" />
                <path d="M46.0856 26.5553C45.6426 26.4999 44.5144 26.846 43.8292 26.846C43.144 26.846 40.59 26.8875 40.3616 27.1159C40.0848 27.3997 41.9466 27.6074 43.3101 27.6074C44.6736 27.6074 45.4419 27.8773 46.0925 28.0088C46.7362 28.1403 47.3799 26.7145 46.0925 26.5553" fill="white" />
                <path d="M30.7618 45.4435C30.2289 45.568 29.7306 46.0249 29.9105 46.5647C30.0905 47.1046 30.5819 47.5545 31.5716 47.506C32.5614 47.4645 33.7242 46.8347 34.7139 46.7447C35.7037 46.6547 37.1779 46.2948 35.9736 46.2048C34.7624 46.1148 33.6065 45.9764 33.1566 45.8449C32.6998 45.7134 31.9385 45.1735 30.7757 45.4435" fill="white" />
                <path d="M40.0849 46.0803C39.372 46.1149 38.7906 46.2118 38.8322 46.4402C38.8737 46.6686 40.7148 46.7102 42.3275 46.7102C43.9401 46.7102 47.4769 47.0493 48.2452 47.1462C49.4149 47.2916 50.287 47.4369 50.7023 46.6133C51.1175 45.7896 50.3977 45.2152 49.6848 45.2705C48.3421 45.3743 48.5636 45.7758 47.2624 45.7273C45.9612 45.6858 40.784 46.0526 40.078 46.0872" fill="white" />
                <path d="M40.7626 51.5067C39.3644 51.5482 37.4888 51.6867 36.416 51.2368C35.3708 50.8007 33.7928 50.9669 33.7374 51.7767C33.6751 52.5864 33.8551 53.0363 34.9763 53.0363C36.0976 53.0363 36.1391 52.5865 38.0286 52.4065C39.9112 52.2265 41.9323 52.275 43.185 52.4549C44.4378 52.6349 45.5798 52.9464 46.4657 52.6764C47.3517 52.4065 47.3586 51.3129 46.8256 51.0153C46.2858 50.7108 45.5244 50.9115 44.1056 51.4167C42.922 51.8389 41.3993 51.4929 40.7626 51.5136" fill="white" />
                <path d="M42.3547 64.0758C41.5725 63.6329 37.6482 63.7228 37.0391 63.5429C36.4231 63.3629 35.1703 62.8092 34.1875 63.1691C33.1977 63.529 33.2116 64.152 33.7307 64.5811C34.2498 65.0102 36.0424 64.8303 37.0391 64.6503C38.0357 64.4703 43.3098 64.6157 42.3547 64.0758Z" fill="white" />
                <path d="M46.473 63.0515C45.7878 63.01 45.1372 63.8475 44.8327 63.9513C44.535 64.0551 45.8985 64.775 46.8329 64.6504C47.7673 64.5327 47.7881 63.1346 46.473 63.0515Z" fill="white" />
                <path d="M34.0702 15.1351C33.5165 15.239 32.8174 15.9726 33.3504 16.7478C33.8903 17.523 34.8039 17.0454 35.8698 16.7478C36.9356 16.4502 37.8354 16.4502 37.5378 16.1526C37.2402 15.855 35.3853 14.8929 34.0702 15.1351Z" fill="white" />
                <path d="M40.084 16.1387C40.6446 16.4709 42.8871 16.2563 43.8284 16.4501C44.7697 16.6439 45.5934 17.08 46.4655 16.8446C47.3375 16.6024 47.7182 15.2112 46.6177 15.0382C45.5172 14.8582 44.7074 15.142 43.3024 15.4396C41.8974 15.7372 39.0458 15.5227 40.084 16.1387Z" fill="white" />
              </svg>
            </div>

            <div className="quierescono" style={showImagen ? null : { textAlign: "center" }}>
              ¿Quieres conocer los planes {Tamaño01 ? (<br />) : null} que tenemos para ti?{Tamaño01 ? (<span>_</span>) : null}
            </div>
            <div className={`flex flex-wrap ${showImagen ? "flex-row" : "justify-content-center align-items-center flex-column"}`} style={{ marginTop: "40px" }}>
              <p className="texto5plan"> Contamos con 5 planes para identificar enfermedades {showImagen ? (<br />) : null} ediante el análisis de genómicos precisos y detallados.</p>
              <a href="/#planes">
                <button className="buttoncontacto" style={showImagen ? { marginLeft: "150px" } : null}>
                  {Tamaño02 ? "Conoce los planes que tenemos para ti" : "Ir"}
                  <svg style={{ marginLeft: "10px" }} xmlns="http://www.w3.org/2000/svg" width="25" height="15" viewBox="0 0 15 16" fill="none">
                    <path d="M13.85 8.70711C14.2405 8.31658 14.2405 7.68342 13.85 7.29289L7.486 0.928932C7.09548 0.538408 6.46231 0.538408 6.07179 0.928932C5.68127 1.31946 5.68127 1.95262 6.07179 2.34315L11.7286 8L6.07179 13.6569C5.68127 14.0474 5.68127 14.6805 6.07179 15.0711C6.46231 15.4616 7.09548 15.4616 7.486 15.0711L13.85 8.70711ZM0 9H13.1429V7H0V9Z" fill="white" />
                  </svg>
                </button>
              </a>

            </div>
          </div>
          {showImagen ? (
            <div className="">
              <img src={Imgcontacto1}
                style={{ width: "380px ", height: "320px", marginLeft: "-55px" }}></img>
            </div>
          ) : null}

        </div>
      </div>
      <div style={{ marginTop: "-100px" }}>
        {showFooter ? (
          <Footer link="/Contacto" />
        ) : (
          <FooterMovil link="/Contacto" />
        )}
      </div>
    </div>
  );
}

export default Contacto;
