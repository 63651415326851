import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'

const Login = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const [validated, setValidated] = useState(false)

  useEffect(() => {
    // Verificar si la sesión ya está iniciada
    if (localStorage.getItem('loggedIn') === 'true') {
      navigate('/admin/panel')
    }
  }, [navigate])

  const handleLogin = async (e) => {
    e.preventDefault()

    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }

    setValidated(true)

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'Login.php', {
        username,
        password,
      })

      if (response.data.message) {
        const { id, username, name } = response.data.user
        setLoggedIn(true)
        localStorage.setItem('loggedIn', true)
        localStorage.setItem('userId', id)
        localStorage.setItem('username', username)
        localStorage.setItem('name', name)
        navigate('/admin/panel')
      } else {
        setError(response.data.error)
      }
    } catch (error) {
      console.error(error)
      setError('Error al iniciar sesión')
    }
  }

  return (
    <div className=" insertarArticulo contenedor-login d-flex align-items-center justify-content-center w-100">
      <div className="login contenerdor-shadow">
        <h2 className="mb-3 text-center insertarArticuloTitulo">Iniciar sesión</h2>
        <Form noValidate validated={validated} onSubmit={handleLogin}>
          <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nombre de usuario"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">Ingrese un usuario</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="validationCustom02">
            <Form.Label>Contaseña</Form.Label>
            <Form.Control
              type="password"
              placeholder="Contraseña"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">Ingrese una contraseña</Form.Control.Feedback>
          </Form.Group>
          <Button type="submit" variant="success" className="w-100">
            Iniciar sesión
          </Button>
          {error && (
            <div>
              <div className="mt-3 bg-danger pt-3 px-3 pb-1 text-center rounded">
                <p className="text-white">{error}</p>
              </div>
            </div>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Login
