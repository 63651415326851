import React, { useState } from "react";
import { Button, Container, Modal, Nav } from "react-bootstrap";
import logo from "../images/logo-footer.png";
import Group203 from "../images/contacto/Group203.svg";
import Group204 from "../images/contacto/Group204.svg";
import Group205 from "../images/contacto/Group205.svg";
import Group206 from "../images/contacto/Group206.svg";
import Group31 from "../images/contacto/Group31.svg";
import "../index.css"
import TextoClausulacon from "./TextoClausulacon";
import Textoacercanosotros from "./Textoacercanosotros";
import TextoPoliticaPrivacidad from "./TextoPoliticasPrivacidad";
function Footer(props) {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };
  return (
    <><div className="margin-t">
      <div className="sep" style={{ marginBottom: "30px" }} key={'sep'}></div>
      <div className="paddingFooter">
        <img src={logo} alt="logo" className="img-fluid" style={{
          width: "255.708px",
          height: "63.57px"
        }} />
        <Nav className="mt-3 nav-footer">
          <Nav.Item className="me-5">
            <Nav.Link href="/#acerca" className="link-nav-footer p-1">
              Acerca de genea
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="me-5">
            <Nav.Link onClick={handleShowModal} className="link-nav-footer p-1">
              Términos y condiciones
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="me-5">
            <Nav.Link onClick={handleShowModal1} className="link-nav-footer p-1">
              Políticas de privacidad
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="me-5">
            <Nav.Link href="/resultados" className="link-nav-footer p-1">
              Resultados
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="me-5">
            <Nav.Link href="/Contacto" className="link-nav-footer p-1">
              Contacto
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Nav className="mt-3 nav-footer">
          <p className="parrafo-footer me-3">
          Jr. Horacio Ballón N.° 103 - San Borja
            <br />
            ©Genea, Todos los derechos reservados.
          </p>
          
          <p className="parrafo-footer me-3">|</p>
          <p className="parrafo-footer me-3">CEL +51 960 645 355</p>
          <div className="ms-auto">
            <div className="icon-footer">
      
            <a href="https://www.linkedin.com/in/genea-per%C3%BA-3593a2285/" target="_blank"
                  rel="">
                  <img style={{ width: "30px" }} src={Group203} alt="" />
                </a>
                <a href="https://www.instagram.com/genea.peru/" target="_blank" rel="">
                  <img style={{ width: "30px" }} src={Group204} alt="" />
                </a>
                <a href="https://www.facebook.com/people/Genea/100094922400180/" target="_blank" rel="">
                  <img style={{ width: "30px" }} src={Group205} alt="" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=51960645355"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img style={{ width: "30px" }} src={Group206} alt="" />
                </a>
            </div>


            {/* <div className=" flex justify-content-center align-items-center" style={{ paddingLeft: "35px" }}>
      <a href={props.link}>
        <img src={Group31} alt="" />
      </a>
    </div> */}
          </div>
          <iframe
        className="mapa"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.4291301794146!2d-76.9968646!3d-12.0827473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c632c308ecfb%3A0xe8f0fd6ade15a653!2sJr.%20Horacio%20Ball%C3%B3n%20103%2C%20San%20Borja%2015021!5e0!3m2!1ses-419!2spe!4v1713805530443!5m2!1ses-419!2spe"
        width={400}
        height={300}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
    />
          

        </Nav>
      </div>
      <Modal
        className="custom-modal custom-slide-in"
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="encabezadotermi">Términos y Condiciones</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body" style={{ padding: "30px" }}>
          <Textoacercanosotros />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal} style={{ backgroundColor: "#172b3e", width: "100px" }}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="custom-modal custom-slide-in"
        show={showModal1}
        onHide={handleCloseModal1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="encabezadocla">POLÍTICAS DE PRIVACIDAD</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body" style={{ padding: "30px" }}>
          <TextoPoliticaPrivacidad />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal1} style={{ backgroundColor: "#172b3e", width: "100px" }}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    
    
    </>
  );
}

export default Footer;