import React, { useEffect, useState } from 'react';
import AcercaNosotros from "../images/AcercaNosotros.png";
import "../index.css";
import { Container } from 'react-bootstrap';
import FechaAcercaNosotros from "../images/FechaAcercaNosotros.png"
function Noticias() {
//const [UsarBr, setUsarBr] = useState(true);

  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 768) {
  //       setUsarBr(false);
  //     }

  //     /* MUESTRA EL BANNER GRANDE */
  //     else {
  //       setUsarBr(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);


  const [UsarBr, setUsarBr] = useState(window.innerWidth >= 768);
  useEffect(() => {
      const handleResize = () => {
        setUsarBr(window.innerWidth >= 768);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setUsarBr(window.innerWidth >= 768);
  }, []);
  

  return (
    <div id="acerca" className='flex flex-wrap flex-row align-items-center' style={{marginTop:"120px"}}  >
      <Container className='ContenedorAcercaNosotros' style={{ gridGap: "40px" }}>
        <div>
          <img className="AcercaNosotrosImagen img-fluid" src={AcercaNosotros} alt="" />
        </div>
        <div className='flex flex-wrap flex-column justify-content-center'>
          <p className='AcercaNosotrosTitulo' >ACERCA DE </p><p className='AcercaNosotrosTitulo'  style={{ marginTop: "-40px" }}> NOSOTROS _ </p>
          <p className='AcercaNosotrosSubtitulo' >¿Quiénes somos?</p>
          <div>
            <p className='AcercaNosotrostexto'>Somos Genea, laboratorio molecular creado con la misión de mejorar la salud de las personas con {UsarBr ? (<br />) : null} 
              ética y pasión. </p>

          </div>
          <a href="/AcercaNosotros" style={{ gridGap: "20px", textDecoration: "none" }} className='flex flex-nowrap flex-row align-items-center'>
            <p className='AcercaNosotrosVerMas'>Ver Más</p>
            <img src={FechaAcercaNosotros} alt="" className='' style={{ width: "80.5px", height: "14px" }} />
          </a>
        </div>
      </Container>

    </div>
    /*si lees esto te gusta la fea*/
  )
}

export default Noticias
