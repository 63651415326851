// import React from 'react'
// import "../index.css";
// import "../PlanComponent.css";
// function ListaEnfermedad(props) {
//     return (
//         <div className='flex flex-nowrap flex-row ' style={{gridGap:"20px", marginTop:"-8px"}}>
//             <div className='flex flex-wrap'>
//                 <img src={props.imgIcono} alt="" style={{width: "15.424px", height: "20px"}}/>
//             </div>
//             <div className='flex flex-wrap' style={{textAlign:"left"}}>
//                 <p style={{
//                     color:      "#000",
//                     fontFamily: "Rubik",
//                     fontSize:   "16px",
//                     fontStyle:  "normal",
//                     fonWweight: "400",
//                     lineHeight: "normal"
//                 }}>{props.tituloEnfermedad}</p>
//             </div>
//         </div>
//     );
// }

// export  default ListaEnfermedad;

import React from 'react';
import "../index.css";
import "../PlanComponent.css";

function ListaEnfermedad(props) {
  return (
    <div className='flex flex-nowrap flex-row' style={{ gridGap: "20px", marginTop: "-9px" }}>
      <div className='flex flex-wrap'>
        <img src={props.imgIcono} alt="" style={{ width: "15.424px", height: "17px", marginTop:"2px" }} />
      </div>
      <div className='flex flex-column' style={{ textAlign: "left" }}>
        <p style={{
          color: "#000",
          fontFamily: "Rubik",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal"
        }}>{props.tituloEnfermedad}</p>
        
        {props.tituloEnfermedad2 && (
          <p style={{
            color: "#000",
            fontFamily: "Rubik",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginTop: "-10px"
          }}>{props.tituloEnfermedad2}</p>
        )}
      </div>
    </div>
  );
}

export default ListaEnfermedad;
