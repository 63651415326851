import React, { useEffect, useState } from 'react'
import Header from './Header'
import Secuencia from './Secuencia'
import Planes from './Planes'
import Elegir from './Elegir'
import Noticias from './Noticias'
import Network from './Network'
import Partners from './Partners'
import Footer from './Footer'
import FooterMovil from './FooterMovil'
import AcercaNosotros from "./AcercaNosotros"
import NuestrasCertificaciones from './NuestrasCertificaciones'

function Paginaprincipal() {

    // const [showFooter, setShowFooter] = useState(true);
    // useEffect(() => {
    //     const handleResize = () => {
    //         /* MUESTRA EL BANNER PEQUEÑO */
    //         if (window.innerWidth <= 550) {
    //             setShowFooter(false);
    //         }

    //         /* MUESTRA EL BANNER GRANDE */
    //         else {
    //             setShowFooter(true);
    //         }
    //     };

    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []);


    const [showFooter, setShowFooter] = useState(window.innerWidth >= 550);
    useEffect(() => {
        const handleResize = () => {
            setShowFooter(window.innerWidth >= 550);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setShowFooter(window.innerWidth >= 550);
    }, []);

    return (
        <div>
            <Header key={'header'} />,
            <Secuencia key={'secuencia'} />,
            <Planes key={'planes' }  smooth />,
            <Elegir key={'elegir'} />,
            <AcercaNosotros key={"acerca"} />,
            <Noticias key={'noticias'} />,
            
            <NuestrasCertificaciones />,
            {/* <Network key={'network'} />,
            <Partners key={'partners'} />,
            <div className="sep margin-t" key={'sep'}></div>, */}
            {showFooter ? (<Footer link="/#Inicio" />) : (<FooterMovil link="/#Inicio"/>)}
        </div>
    )
}

export default Paginaprincipal;