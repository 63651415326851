// import React, { useEffect, useState } from 'react'
// import { Container, Row, Col, Table, ButtonGroup, Modal } from 'react-bootstrap'
// import { Form, Button } from 'react-bootstrap'
// import { useNavigate, useParams } from 'react-router-dom'

// import Swal from 'sweetalert2';

// function BlogAdmin() {
//     const navigate = useNavigate()
//     const [datosCargados, setDatosCargados] = useState(false)
//     const [articulos, setArticulos] = useState([])
//     const [show, setShow] = useState(false);
//     const [idArt, setIdArt] = useState(null);
//     const handleClose = () => setShow(false);
//     const handleShow = (ids) => {
//         setShow(true);
//         setIdArt(ids);
//         console.log("Se ejecutó", ids);

//     }

//     useEffect(() => {
//         cargardatos()
//     }, [])

//     const cargardatos = () => {
//         fetch(process.env.REACT_APP_API_URL + 'articulos.php')
//             .then((respuesta) => respuesta.json())
//             .then((datosRespuesta) => {
//                 setDatosCargados(true)
//                 setArticulos(
//                     datosRespuesta.map((articulo) => ({
//                         ...articulo,
//                         contenido: articulo.contenido.replace(/\n/g, '<br>'),
//                     }))
//                 )
//             })
//             .catch(console.log)
//     }

//     const showAlert = () => {
//         handleClose();
//         Swal.fire({
//             title: '¡EXCELENTE!',
//             text: 'Artículo eliminado correctamente',
//             icon: 'success',
//             confirmButtonText: "Ok",
//             showCloseButton: true,
//         }).then((result) => {
//             if (result.isConfirmed) {
//                 window.location.reload();
//             }
//         });
//     }

//     const showAlert1 = () => {
//         Swal.fire({
//             title: '¡UPS!',
//             text: 'Hubo un error, inténtelo de nuevo',
//             icon: 'error',
//             confirmButtonText: "Ok",
//             showCloseButton: true,
//         })
//     }

//     const handleDelete = (e) => {
//             fetch(`http://localhost/genea_front/api/articulos.php?eliminar=${idArt}`, {
//                 method: "POST",
//             })
//                 .then((response) => response.json())
//                 .then((data) => {
//                     if (data.success) {
//                         showAlert();


//                     } else {
//                         showAlert1();

//                     }
//                 })
//                 .catch((error) => {
//                     console.log("entró al catch error")
//                     console.error("Error:", error);
//                 });

//     };

//     // Función para recortar el contenido a una longitud específica
//     const recortarContenido = (contenido, longitudMaxima) => {
//         if (contenido.length <= longitudMaxima) {
//             return contenido
//         }
//         return contenido.slice(0, longitudMaxima) + '...'
//     }

//     if (!datosCargados) {
//         return (
//             <div>
//                 <h1>Error</h1>
//             </div>
//         )
//     } else {
//         return (
//             <Container className="my-5">
//                 <Table striped bordered hover>
//                     <tr>
//                         <th style={{textAlign:"center"}}>ID</th>
//                         <th style={{textAlign:"center"}}>Título</th>
//                         <th style={{textAlign:"center"}}>Acción</th>
//                     </tr>
//                     {articulos.map((articulo) => (
//                         <tr>
//                             <td><p style={{display:"flex", alignItems:"center", justifyContent:"center"}}>{articulo.id}</p></td>
//                             <td><p style={{marginLeft:"30px"}} className="fw-bold">{articulo.titulo}</p></td>
//                             <td style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
//                                 <a href={'./EditarBlog/' + articulo.id}><Button name={articulo.id} style={{backgroundColor:"green", border:"1px solid green"}}>Editar</Button></a>
//                                 <a><Button onClick={() => handleShow(articulo.id)} style={{backgroundColor:"red", border:"1px solid red"}}>Eliminar</Button></a>
//                             </td>
//                         </tr>
//                     ))}
//                 </Table>

//             <Modal show={show} onHide={handleClose} centered>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Eliminar Artículo</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <Form.Label>¿Está seguro que desea eliminar este artículo?</Form.Label>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleClose}>
//                     Cerrar
//                     </Button>
//                     <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
//                 </Modal.Footer>
//             </Modal>
//             </Container>
//         )
//     }
// }

// export default BlogAdmin


import React, { useEffect, useState } from 'react';
import { Container, Table, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function BlogAdmin() {
  const navigate = useNavigate();
  const [datosCargados, setDatosCargados] = useState(false);
  const [articulos, setArticulos] = useState([]);
  const [show, setShow] = useState(false);
  const [idArt, setIdArt] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (ids) => {
    setShow(true);
    setIdArt(ids);
    console.log("Se ejecutó", ids);
  };

  useEffect(() => {
    cargardatos();
  }, []);

  const cargardatos = () => {
    fetch(process.env.REACT_APP_API_URL + 'articulos.php')
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        setDatosCargados(true);
        setArticulos(
          datosRespuesta.map((articulo) => ({
            ...articulo,
            contenido: articulo.contenido.replace(/\n/g, '<br>'),
          }))
        );
      })
      .catch(console.log);
  };

  const showAlert = () => {
    handleClose();
    Swal.fire({
      title: '¡EXCELENTE!',
      text: 'Artículo eliminado correctamente',
      icon: 'success',
      confirmButtonText: "Ok",
      showCloseButton: true,
    });
  }

  const showAlert1 = () => {
    Swal.fire({
      title: '¡UPS!',
      text: 'Hubo un error, inténtelo de nuevo',
      icon: 'error',
      confirmButtonText: 'Ok',
      showCloseButton: true,
    });
  };

  const eliminarArticulo = (id) => {
    fetch(process.env.REACT_APP_API_URL + `articulos.php?eliminar=${id}`, {
      method: 'POST',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          showAlert();
          // Eliminar el artículo eliminado del estado
          setArticulos((prevArticulos) =>
            prevArticulos.filter((articulo) => articulo.id !== id)
          );
        } else {
          showAlert1();
        }
      })
      .catch((error) => {
        console.log('entró al catch error');
        console.error('Error:', error);
      });
  };

  const handleDelete = () => {
    eliminarArticulo(idArt);
  };

  // Función para recortar el contenido a una longitud específica
  const recortarContenido = (contenido, longitudMaxima) => {
    if (contenido.length <= longitudMaxima) {
      return contenido;
    }
    return contenido.slice(0, longitudMaxima) + '...';
  };

  if (!datosCargados) {
    return (
      <div>
        {/* <h1>Error</h1> */}
      </div>
    );
  } else {
    return (
      <Container className="my-5">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>ID</th>
              <th style={{ textAlign: 'center' }}>Título</th>
              <th style={{ textAlign: 'center' }}>Acción</th>
            </tr>
          </thead>
          <tbody>
            {articulos.map((articulo) => (
              <tr key={articulo.id}>
                <td>
                  <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{articulo.id}</p>
                </td>
                <td>
                  <p style={{ marginLeft: '30px' }} className="fw-bold">
                    {articulo.titulo}
                  </p>
                </td>
                <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <a href={'./EditarBlog/' + articulo.id}>
                    <Button name={articulo.id} style={{ backgroundColor: 'green', border: '1px solid green' }}>
                      Editar
                    </Button>
                  </a>
                  <Button onClick={() => handleShow(articulo.id)} style={{ backgroundColor: 'red', border: '1px solid red' }}>
                    Eliminar
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar Artículo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¿Está seguro que desea eliminar este artículo?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cerrar
            </Button>
            <Button variant="danger" onClick={handleDelete}>Eliminar</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

export default BlogAdmin;
