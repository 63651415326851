import React, { useState } from "react";
import logo from "../images/logo-l.png";
import doctor from "../images/doctor.png";
import { Form, Modal, Button } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import cupolimitado from "../images/cupolimitado.png";
import { Accordion, Card } from "react-bootstrap";
import terminos1 from "../images/terminos-y-condiciones.png";
import terminos2 from "../images/terminos-y-condiciones_2.png";
import terminos3 from "../images/terminos3.png";
import terminos4 from "../images/terminos4.png";
import TextoClausulacon from "./TextoClausulacon";
import axios from "axios";
import Swal from "sweetalert2";
import Textoacercanosotros from "./Textoacercanosotros";


function Footer() {
  return (
    <footer className="footer">
      <div className="footer-social ms-auto">
        <a
          href="https://api.whatsapp.com/send?phone=51960645355"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-whatsapp"></i>
        </a>
      </div>
    </footer>
  );
}

// function Content() {
//     const [validated, setValidated] = useState(false);
//     const handleSubmit = (event) => {
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             event.preventDefault();
//             event.stopPropagation();
//         }

//         setValidated(true);
//     };

function Content() {
  const [validated, setValidated] = useState(false);
  const [form, setForm] = useState({
    Nombre: "",
    Email: "",
    Telefono: "",
    Checkbox: false,
    MedioInformacion: "",
    AceptarTerminos: false,
    Finalidadesadiciones: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: fieldValue,
    }));
  };
  const showAlert = () => {
    Swal.fire({
      title: "¡EXCELENTE!",
      text: "Formulario enviado correctamente",
      icon: "success",
      confirmButtonText: "Ok",
      showCloseButton: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    });
  };

  const showAlert1 = () => {
    Swal.fire({
      title: "¡UPS!",
      text: "Hubo un error, inténtelo de nuevo",
      icon: "error",
      confirmButtonText: "Ok",
      showCloseButton: true,
    });
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  // const handleSubmit = async (e) => {
  //     e.preventDefault();

  //     const formEl = e.currentTarget;
  //     if (formEl.checkValidity() === false) {
  //         e.stopPropagation();
  //     } else {
  //         try {
  //             const response = await axios.post('https://formspree.io/f/xjvqvyvq', form);
  //             console.log(response);
  //             showAlert();
  //             setForm({
  //                 Nombre: '',
  //                 Email: '',
  //                 Telefono: '',
  //                 Checkbox: false,
  //                 MedioInformacion: '',
  //                 AceptarTerminos: false,

  //             });
  //             setValidated(false);
  //             // window.location.reload();
  //         } catch (error) {
  //             console.log(error);
  //             alert('Ocurrió un error al enviar el formulario');
  //         }
  //     }

  //     setValidated(true);
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formEl = e.currentTarget;
    if (formEl.checkValidity() === false) {
      e.stopPropagation();
    } else {
      try {
        const finalidades = form.Finalidadesadiciones ? "Sí" : "No";

        if (form.MedioInformacion === "") {
          setForm((prevForm) => ({
            ...prevForm,
            MedioInformacion: "",
          }));
        }

        const response = await axios.post(

          "https://formspree.io/f/xleydwlk",
          {
            ...form,
            Finalidadesadiciones: finalidades,
          }
        );
        console.log(response);
        showAlert();
        setForm({
          Nombre: "",
          Email: "",
          Telefono: "",
          Checkbox: false,
          MedioInformacion: "",
          AceptarTerminos: false,
          Finalidadesadiciones: false,

        });
        setValidated(false);
      } catch (error) {
        console.log(error);
        showAlert1();
      }
    }

    setValidated(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal1 = () => {
    setShowModal1(true);
  };

  const handleCloseModal1 = () => {
    setShowModal1(false);
  };
  return (
    <div>
      <div className="d-flex">
        <div className="ms-auto me-5 mt-4">
          <img src={logo} alt="logo" className="img-fluid mb-5 mt-3 logo" />
        </div>
      </div>
      <div className="container">
        <div className="row" style={{ gridGap: "300px" }}>
          <div className="col col-css">
            <div className="d-flex justify-content-center">
              <img
                src={doctor}
                alt="doctor"
                className="img-fluid position-absolute bottom-0"
                style={{ width: "auto", height: "90%" }}
              />
            </div>
          </div>
          <div className="col">
            <div className="container derecho">
              <div className="d-flex flex-row">
                <div className="cont-titulo h-full">
                  <h3 className="titulo">Inscríbete aquí</h3>
                  <h3 className="titulo">a nuestro Webinar</h3>
                  <h3 className="titulo-2">#DependeDeTi</h3>
                </div>
                <div>
                  <img src={cupolimitado} alt="doctor" className="cupo" />
                </div>
              </div>
              <div className="cont bg-white round">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label className="label-css font-weight-bold">
                      Nombre y apellido:
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Escribe aquí"
                      required
                      name="Nombre"
                      className="custom-input"
                      value={form.Nombre}
                      onChange={handleChange}
                    />

                    {/* <Form.Control.Feedback type="invalid">
                                            Por favor ingresa tu nombre y apellido.
                                        </Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="validationCustom02">
                    <Form.Label className="label-css">
                      Correo electrónico:
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Escribe aquí"
                      required
                      name="Email"
                      className="custom-input"
                      value={form.Email}
                      onChange={handleChange}
                    />

                    {/* <Form.Control.Feedback type="invalid">
                                            Por favor ingresa un correo electrónico válido.
                                        </Form.Control.Feedback> */}
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="validationCustom03">
                    <Form.Label className="label-css">Teléfono:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Escribe aquí"
                      required
                      name="Telefono"
                      className="custom-input"
                      value={form.Telefono}
                      onChange={handleChange}
                    />

                    {/* <Form.Control.Feedback type="invalid">
                                            Por favor ingresa tu número de teléfono.
                                        </Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="validationCustom01">
                    <Form.Label className="label-css font-weight-bold">
                      ¿A través de que medio te enteraste del webinar?
                    </Form.Label>
                    <Form.Select
                      defaultValue=""
                      style={{ borderRadius: "5px", fontFamily: "Arial" }}
                      name="MedioInformacion"
                      value={form.MedioInformacion}
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled hidden>
                        Seleccione una opción
                      </option>
                      <option value="Facebook" style={{ margin: "10px 0" }}>
                        Facebook
                      </option>
                      <option value="Instagram" style={{ margin: "10px 0" }}>
                        Instagram
                      </option>
                      <option
                        value="Invitación personal"
                        style={{ margin: "10px 0" }}
                      >
                        Invitación personal
                      </option>
                      <option value="Email" style={{ margin: "10px 0" }}>
                        Email
                      </option>
                      <option value="Otros" style={{ margin: "10px 0" }}>
                        Otros
                      </option>
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                                            Por favor selecciona un medio de información.
                                        </Form.Control.Feedback> */}
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="validationCustom04">
                    <Form.Check
                      type="checkbox"
                      label="Quiero recibir boletines informáticos y/o Newsletters de GENEA"
                      className="fuente-check"
                      name="Checkbox"
                      checked={form.Checkbox}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/* <Form.Group className="mb-2" controlId="validationCustom05">
                                        <Form.Check
                                            type="checkbox"
                                            label="Aceptar términos y condiciones"
                                            className="fuente-check"
                                            name="AceptarTerminos"
                                            required
                                            checked={form.AceptarTerminos}
                                            onChange={handleChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Debes aceptar los términos y condiciones.
                                        </Form.Control.Feedback>
                                    </Form.Group> */}
                  <Form.Group
                    className="mb-2 flex"
                    controlId="validationCustom05"
                  >
                    <Form.Check
                      type="checkbox"

                      className="fuente-check"
                      name="AceptarTerminos"
                      required
                      checked={form.AceptarTerminos}
                      onChange={handleChange}
                    />
                    <p style={{ fontSize: "11px", paddingLeft: "5px" }}>  Aceptar  <a
                      className="fuente-check cursor-pointer"
                      onClick={handleShowModal}
                      style={{ fontSize: "11px" }}
                    >
                      {/* <img
                        src={terminos3}
                        style={{
                          width: "21px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                      ></img> */}
                      términos y condiciones.
                    </a>
                    </p>
                    <Modal
                      className="custom-modal custom-slide-in"
                      show={showModal}
                      onHide={handleCloseModal}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="encabezadotermi" >Términos y Condiciones</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="custom-modal-body" style={{ padding: "30px" }}>
                        <Textoacercanosotros />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal} style={{ backgroundColor: "#172b3e", width: "100px" }}>
                          OK
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Form.Control.Feedback type="invalid">
                      Debes aceptar los términos y condiciones.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-2 flex"
                    controlId="validationCustom05"
                  >
                    <Form.Check
                      type="checkbox"

                      className="fuente-check"
                      name="Finalidadesadiciones"

                      checked={form.Finalidadesadiciones}
                      onChange={handleChange}
                    />
                    <p style={{ fontSize: "11px", paddingLeft: "5px" }}> He leído y acepto el tratamiento de mis datos personales para <a
                      className="fuente-check cursor-pointer"
                      onClick={handleShowModal1}
                      style={{ fontSize: "11px" }}
                    >
                      {/* <img
                        src={terminos3}
                        style={{
                          width: "21px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                      ></img> */}
                      finalidades adicionales.
                    </a></p>
                    <Modal
                      className="custom-modal custom-slide-in"
                      show={showModal1}
                      onHide={handleCloseModal1}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title className="encabezadocla">CLÁUSULA DE CONSENTIMIENTO PARA FINALIDADES DIFERENTES</Modal.Title>
                      </Modal.Header>
                      <Modal.Body className="custom-modal-body" style={{ padding: "30px" }}>
                        <TextoClausulacon />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal1} style={{ backgroundColor: "#172b3e", width: "100px" }}>
                          OK
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Form.Group>


                  <Form.Group>
                    <div className="d-flex justify-content-center">
                      <div className="mx-auto">
                        <button type="submit" className="boton" >
                          Aceptar
                        </button>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Content;
