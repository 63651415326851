import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2';

const showAlert = () => {
  Swal.fire({
      title: '¡EXCELENTE!',
      text: 'Artículo creado correctamente',
      icon: 'success',
      confirmButtonText: "Ok",
      showCloseButton: true,
  }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
          window.location.reload();
      }
  })
}

const showAlert1 = () => {
  Swal.fire({
      title: '¡UPS!',
      text: 'Hubo un error, inténtelo de nuevo',
      icon: 'error',
      confirmButtonText: "Ok",
      showCloseButton: true,
  })
}
const InsertarArticulo = () => {
  const navigate = useNavigate()
  const [titulo, setTitulo] = useState('')
  const [contenido, setContenido] = useState('')
  const [imagen, setImagen] = useState(null)
  const [validated, setValidated] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }

    setValidated(true)

    // Crear objeto FormData para enviar los datos y la imagen
    const formData = new FormData()
    formData.append('titulo', titulo)
    formData.append('contenido', contenido)
    formData.append('imagen', imagen)

    // Realizar la petición POST al backend para insertar el producto
    fetch(process.env.REACT_APP_API_URL + 'articulos.php?insertar=1', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Mostrar mensaje de éxito o error según la respuesta del backend
        if (data.success) {
          showAlert()
          navigate('/admin/panel/articulos')
        } else {
          showAlert1()
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        
      })
  }

  return (
    <div className="contenedor-dashboard d-flex align-items-center justify-content-center insertarArticulo">
      <div className="dashboard-form">
        <div className="insertarArticuloCard">
          <div className="card-header mb-4" style={{ borderBottom: "1px solid black" }}>
            <h1 className="text-center fw-bold insertarArticuloTitulo">INSERTAR ARTíCULO</h1>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3 mx-3" controlId="validationCustom01">
              <Form.Label>Titulo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese título del artículo"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 mx-3" controlId="validationCustom02">
              <Form.Label>Contenido</Form.Label>
              <Form.Control
                as="textarea"
                placeholder='Escribir aqui...'
                rows={15}
                value={contenido}
                onChange={(e) => setContenido(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 mx-3" controlId="validationCustom05">
              <Form.Control type="file" onChange={(e) => setImagen(e.target.files[0])} required />
            </Form.Group>
            <div className="card-footer">
              <div className="text-center align-middle">
                <div className="d-flex justify-content-center">
                  <Button type="submit" className="boton" style={{border: "#07a34f",padding: "15px 40px",marginTop: "10px",borderRadius: "35px",color: "#fff",fontWeight: "bold"}}
                  >
                    Insertar Articulo
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default InsertarArticulo
