// import React, { useState } from 'react'
// import { useNavigate, useParams } from 'react-router-dom'
// import { Form, Button } from 'react-bootstrap'
// import Swal from 'sweetalert2';
// import { useEffect } from 'react';


// const showAlert = () => {
//     Swal.fire({
//         title: '¡EXCELENTE!',
//         text: 'Artículo creado correctamente',
//         icon: 'success',
//         confirmButtonText: "Ok",
//         showCloseButton: true,
//     }).then((result) => {
//         /* Read more about isConfirmed, isDenied below */
//         if (result.isConfirmed) {
//             window.location.reload();
//         }
//     })
// }

// const showAlert1 = () => {
//     Swal.fire({
//         title: '¡UPS!',
//         text: 'Hubo un error, inténtelo de nuevo',
//         icon: 'error',
//         confirmButtonText: "Ok",
//         showCloseButton: true,
//     })
// }
// const EditarArt = () => {
//     const { id } = useParams()
//     const navigate = useNavigate()
//     const [titulo, setTitulo] = useState('')
//     const [contenido, setContenido] = useState('')
//     const [imagen, setImagen] = useState(null)
//     const [validated, setValidated] = useState(false)

//     useEffect(() => {
//         // Realizar la petición GET al backend para obtener los datos del producto
//         fetch(process.env.REACT_APP_API_URL +`articulos.php?consultar=${id}`)
//             .then((response) => response.json())
//             .then((data) => {
//                 // Verificar si se encontró el producto
//                 if (data.id) {

//                     setTitulo(data.titulo)
//                     setContenido(data.contenido)
//                     setImagen(data.imagen)
//                 } else {
//                     alert('No se encontró el producto')
//                 }
//             })
//             .catch((error) => {
//                 console.error('Error:', error)
//                 alert('Error al realizar la petición')
//             }) 

//     }, [id])

//     const handleSubmit = (e) => {
//         e.preventDefault()

//         const form = e.currentTarget
//         if (form.checkValidity() === false) {
//             e.preventDefault()
//             e.stopPropagation()
//         }

//         setValidated(true)

//         // Crear objeto FormData para enviar los datos y la imagen
//         const formData = new FormData()
//         formData.append('titulo', titulo)
//         formData.append('contenido', contenido)
//         formData.append('imagen', imagen)

//         // Realizar la petición POST al backend para insertar el producto
//         fetch(process.env.REACT_APP_API_URL + `articulos.php?editar=${id}`, {
//             method: 'POST',
//             body: formData,
//         })
//             .then((response) => response.json())
//             .then((data) => {
//                 // Mostrar mensaje de éxito o error según la respuesta del backend
//                 if (data.success) {
//                     showAlert()
//                     navigate('/admin/panel/articulos')
//                 } else {
//                     showAlert1()
//                 }
//             })
//             .catch((error) => {
//                 console.error('Error:', error)

//             })
//     }

//     return (
//         <div className="contenedor-dashboard d-flex align-items-center justify-content-center insertarArticulo">
//             <div className="dashboard-form">
//                 <div className="insertarArticuloCard">
//                     <div className="card-header mb-4" style={{ borderBottom: "1px solid black" }}>
//                         <h1 className="text-center fw-bold insertarArticuloTitulo">EDITAR ARTíCULO</h1>
//                     </div>
//                     <Form noValidate validated={validated} 
//                     onSubmit={handleSubmit}
//                     >
//                         <Form.Group className="mb-3 mx-3" controlId="validationCustom01">
//                             <Form.Label>Titulo</Form.Label>
//                             <Form.Control
//                                 type="text"
//                                 placeholder="Ingrese título del artículo"
//                                 value={titulo}
//                                 onChange={(e) => setTitulo(e.target.value)}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3 mx-3" controlId="validationCustom02">
//                             <Form.Label>Contenido</Form.Label>
//                             <Form.Control
//                                 as="textarea"
//                                 placeholder='Escribir aqui...'
//                                 rows={15}
//                                 value={contenido}
//                                 onChange={(e) => setContenido(e.target.value)}
//                                 required
//                             />
//                         </Form.Group>
//                         <Form.Group className="mb-3 mx-3" controlId="validationCustom05">
//                             <Form.Control type="file" onChange={(e) => setImagen(e.target.files[0])} value={} required />
//                         </Form.Group>
//                         <div className="card-footer">
//                             <div className="text-center align-middle">
//                                 <div className="d-flex justify-content-center">
//                                     <Button type="submit" className="boton" style={{ border: "#07a34f", padding: "15px 40px", marginTop: "10px", borderRadius: "35px", color: "#fff", fontWeight: "bold" }}
//                                     >ACTUALIZAR</Button>
//                                 </div>
//                             </div>
//                         </div>
//                     </Form>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default EditarArt


import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';
import edit from "../images/edit.png";
import "../index.css"

const imageUrl = process.env.REACT_APP_API_URL + 'images/'

const EditarArt = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [titulo, setTitulo] = useState('')
    const [contenido, setContenido] = useState('')
    const [imagen, setImagen] = useState(null)
    const [validated, setValidated] = useState(false)
    const [imagenCargada, setImagenCargada] = useState(null)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + `articulos.php?consultar=${id}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.id) {
                    setTitulo(data.titulo)
                    setContenido(data.contenido)
                    setImagen(data.imagen)
                } else {
                    alert('No se encontró el artículo')
                }
            })
            .catch((error) => {
                console.error('Error:', error)
                alert('Error al realizar la petición')
            })
    }, [id])

    const showAlert = () => {
        Swal.fire({
            title: '¡EXCELENTE!',
            text: 'Artículo actualizado correctamente',
            icon: 'success',
            confirmButtonText: "Ok",
            showCloseButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                navigate('/admin/panel/blog');
            }
        });
    }

    const showAlertImagen = () => {
        setShow(false);
        Swal.fire({
            title: '¡EXCELENTE!',
            text: 'Imagen actualizada correctamente',
            icon: 'success',
            confirmButtonText: "Ok",
        //     showCloseButton: true,
        // }).then((result) => {
        //     if (result.isConfirmed) {
                

        //     }
        });
    }

    const showAlert1 = () => {
        Swal.fire({
            title: '¡UPS!',
            text: 'Hubo un error, inténtelo de nuevo',
            icon: 'error',
            confirmButtonText: "Ok",
            showCloseButton: true,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }

        setValidated(true)

        const formData = new FormData()
        formData.append('id', id)
        formData.append('titulo', titulo)
        formData.append('contenido', contenido)

        fetch(process.env.REACT_APP_API_URL + `articulos.php?editar=${id}`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    showAlert()
                } else {
                    showAlert1()

                }
            })
            .catch((error) => {
                console.error('Error:', error)
            })



    }

    // const handleSubmitImagen = (e) => {

    //     const formData = new FormData()
    //     formData.append('imagen', imagen)


    //     if (show) {
    //         fetch(process.env.REACT_APP_API_URL + `articulos.php?editarImagen=${id}`, {
    //             method: 'POST',
    //             body: formData,
    //         })
    //             .then((response) => response.json())
    //             .then((data) => {
    //                 if (data.success) {
    //                     showAlertImagen()
    //                 } else {
    //                     showAlert1()

    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error('Error:', error)
    //             })

    //     }

    // }

    const handleSubmitImagen = (e) => {
    
        e.preventDefault();
        const formData = new FormData();
        formData.append("imagen", imagenCargada);

        if (show) {
            console.log("entró al condicional")
            fetch(process.env.REACT_APP_API_URL + `articulos.php?editarImagen=${id}`, {
                method: "POST",
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        showAlertImagen();
                        setImagen(data.imagen);

                    } else {
                        showAlert1();

                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    };

    // useEffect(() => {
    //     setImagen(imagenCargada);
    //     console.log("entró al useefect", imagen)
    // }, [handleSubmitImagen])

    return (
        <div className="contenedor-dashboard d-flex align-items-center justify-content-center insertarArticulo">
            <div className="dashboard-form">
                <div className="insertarArticuloCard">
                    <div className="card-header mb-4" style={{ borderBottom: "1px solid black" }}>
                        <h1 className="text-center fw-bold insertarArticuloTitulo">EDITAR ARTÍCULO</h1>
                    </div>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3 mx-3" controlId="validationCustom01">
                            <Form.Label>Título</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Ingrese título del artículo"
                                value={titulo}
                                name='titulo'
                                onChange={(e) => setTitulo(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 mx-3" controlId="validationCustom02">
                            <Form.Label>Contenido</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder='Escribir aquí...'
                                rows={15}
                                value={contenido}
                                name='contenido'
                                onChange={(e) => setContenido(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 mx-3" controlId="validationCustom05">
                            <div style={{ display:"flex", alignItems:"center", justifyContent:"center"}}>
                                <img src={imageUrl + imagen} alt="" style={{ width: "90%", height: "300px" }} />
                                <Button variant="primary" onClick={handleShow} className="btn-editar"
                                style={{ backgroundColor: "white", border: "1px solid #248D63", position: "absolute", marginLeft: "400px", marginTop: "-240px" }}><img src={edit} alt="editar"/></Button>
                            </div>
                            {/* <Form.Control type="file" onChange={(e) => setImagen(e.target.files[0])}/> */}
                        </Form.Group>
                        <div className="card-footer">
                            <div className="text-center align-middle">
                                <div className="d-flex justify-content-center">
                                    <Button
                                        type="submit"
                                        className="boton"
                                        style={{ border: "#07a34f", padding: "15px 40px", marginTop: "10px", borderRadius: "35px", color: "#fff", fontWeight: "bold" }}
                                    >
                                        ACTUALIZAR
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Editar imagen de artículo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="file" onChange={(e) => setImagenCargada(e.target.files[0])} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSubmitImagen}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default EditarArt