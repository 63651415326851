import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import NavBarGeneral from "./NavBarGeneral";
import "../index.css";
import "../PlanComponent.css";
import banner from "../images/plus.png";
import cuidado2 from "../images/cuidado2.svg";
import flecha from "../images/flecha.png";
import ISO_37001 from "../images/isos/ISO_37001.png";
import ISO_45001 from "../images/isos/ISO_45001.png";
import ISO_9001 from "../images/isos/ISO_9001.png";
import ISO_14001 from "../images/isos/ISO_14001.png";
import ISO_27001 from "../images/isos/ISO_27001.png";
import Footer from "./Footer";
import FooterMovil from "./FooterMovil";

function PlanComponent(props) {
  // const [showImage, setShowImage] = useState(true);
  // const [showRegresar, setShowRegresar] = useState(true);
  // const [showFooter, setShowFooter] = useState(true);


  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 1250) {
  //       setShowRegresar(false);
  //     }

  //     /* MUESTRA EL BANNER GRANDE */
  //     else {
  //       setShowRegresar(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);


  const [showRegresar, setShowRegresar] = useState(window.innerWidth >= 1250);
  useEffect(() => {
    const handleResize = () => {
      setShowRegresar(window.innerWidth >= 1250);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setShowRegresar(window.innerWidth >= 1250);
  }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 675) {
  //       setShowImage(false);
  //     }

  //     /* MUESTRA EL BANNER GRANDE */
  //     else {
  //       setShowImage(true);
  //     }
  //   };


  const [showImage, setShowImage] = useState(window.innerWidth >= 675);
  useEffect(() => {
    const handleResize = () => {
      setShowImage(window.innerWidth >= 675);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setShowImage(window.innerWidth >= 675);
  }, []);

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 550) {
  //       setShowFooter(false);
  //     }

  //     else {
  //       setShowFooter(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);


  const [showFooter, setShowFooter] = useState(window.innerWidth >= 550);
  useEffect(() => {
    const handleResize = () => {
      setShowFooter(window.innerWidth >= 550);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setShowFooter(window.innerWidth >= 550);
  }, []);


  const [Consultar, setConsultar] = useState(window.innerWidth >= 260);
  useEffect(() => {
    const handleResize = () => {
      setConsultar(window.innerWidth >= 260);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setConsultar(window.innerWidth >= 260);
  }, []);



  return (
    <div>
      <NavBarGeneral />

      {showImage ? (
        /* PANTALLA GRANDE */
        <div className="flex flex-nowrap flex-row">
          <a
            href="/"
            className="flex flex-nowrap flex-row RegresarAlInicio"
          >

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            {/* 1325 */}
            {showRegresar ? (
              <div>
                <p style={{ marginTop: "-1px" }} >Regresar al inicio</p>
              </div>
            ) : null}
          </a>
        </div>
      ) : (
        /**MOVIL */
        <div className="flex flex-nowrap flex-row align-items-center text-center" style={{ paddingTop: "75px" }}>
          <a
            href="/"
            className="flex flex-nowrap flex-row RegresarAlInicio01 align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>

            <p className="flex flex-wrap" style={{ paddingTop: "14px" }}>Regresar al inicio</p>
          </a>
        </div>
      )}
      <div className="">
        {showImage ? (
          <img
            src={props.banner}
            alt=""
            style={{ width: "100%", height: "auto", paddingTop: "75px" }}
          />
        ) : (
          <img src={props.banner01} alt="" style={{ width: "100%" }} />
        )}
      </div>
      <div className={showImage ? null : "flex justify-content-center "}>
        <img src={props.logo} alt="" className={showImage ? "LogoPlanBanner img-fluid" : "LogoPlanBannerMovil img-fluid"} />
      </div>


      <div className="PlanComponentContenedor flex flex-wrap flex-column">
        <div className="PlanComponentContenedor2 flex flex-wrap flex-row">
          <div
            className="flex flex-wrap flex-column CentrarCont"
            style={props.estilo}
          >
            <img
              src={cuidado2}
              alt=""
              className="img-fluid"
              style={Consultar ? { width: "100px", height: "auto", marginBottom: "10px" } : { width: "70px", height: "auto", marginBottom: "10px" }}
            />
            {/* <p className="PlanComponentPlan1">{props.titulo}</p> */}
            <img src={props.titulo} alt="" className="img-fluid" style={{ width: "230px", height: "55px" }} />
            <p className="PlanComponentTesxto01">{props.descripcion}</p>
             {/* <a href="https://api.whatsapp.com/send?phone=51960645355"
              target="_blank"
              rel="noopener noreferrer">
              <Button
                className={Consultar ? "PlanComponentBoton01" : ""}
                style={{ backgroundColor: "#18C569", border: "0px" }}
              >
               {Consultar ? "Consulta por este plan" : "Consultar"} <img src={flecha} alt="" />
              </Button>
            </a>  */}

          </div>
          <div
            className="flex flex-wrap flex-column"
            style={{ alignContent: "end", textAlign: "center" }}
          >
            <div className="flex flex-wrap flex-row">{props.enfermedades}</div>
            <a href="https://api.whatsapp.com/send?phone=51960645355"
              target="_blank"
              rel="noopener noreferrer">
              {/* <Button
                className={Consultar ? "PlanComponentBoton01" : ""}
                style={{ backgroundColor: "#18C569", border: "0px" }}
              >
               {Consultar ? "Consulta por este plan" : "Consultar"} <img src={flecha} alt="" />
              </Button> */}
            </a>
          </div>
        </div>
        <div className=" flex flex-wrap flex-column align-items-center text-center">
          <p className="NuestroRespaldo">NUESTRO RESPALD<span className="NuestroRespaldo">O</span> </p>
          <p className="CertificacionesInternacionales">5 Certificaciones Internacionales
          </p>
        </div>
        <div
          className="flex flex-wrap flex-row flex-1 ContenedorIsos"
        >
          <img src={ISO_9001} alt="" className="imagenIsos  img-fluid" />
          <img src={ISO_14001} alt="" className="imagenIsos img-fluid" />
          <img src={ISO_27001} alt="" className="imagenIsos img-fluid" />
          <img src={ISO_37001} alt="" className="imagenIsos img-fluid" />
          <img src={ISO_45001} alt="" className="imagenIsos img-fluid" />
        </div>
      </div>
      {showFooter ? (<Footer link={props.linkPlan} />) : <FooterMovil link={props.linkPlan} />}

    </div>
  );
}

export default PlanComponent;
