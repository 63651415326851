import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import video from "../videos/adn.mp4";
import video_genea from "../videos/genea_spot.mp4";
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  Offcanvas,
  OverlayTrigger,
  Overlay,
  Popover,
  Collapse
} from "react-bootstrap";
import { Link } from "react-router-dom";
import vector from "../images/Vector.png";
import "../index.css";
import InClaro from "../images/contacto/InClaro.png";
import VideoClaro from "../images/contacto/VideoClaro.png";
import FacebookClaro from "../images/contacto/FacebookClaro.png";
function Header({ handleScrollPlanes }) {
  const [showBoton, setShowBoton] = useState(true);



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    closeModal();
  };

  const [show, setShow] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [AbrirModal, setAbrirModal] = useState(false);
  const reservaTuCita = () => {
    setAbrirModal(true)
    // window.open(
    //   'https://calendly.com/citasgenea/30min',
    //   'Calendly',
    //   'width=800,height=600'
    // );
  };

  const handleCloseModalReserva = () => {
    setAbrirModal(false)
  }

  // const [UsarBr, setUsarBr] = useState(true);


  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 768) {
  //       setUsarBr(false);
  //     } else {
  //       /* MUESTRA EL BANNER GRANDE */
  //       setUsarBr(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  useEffect(() => {
    const handleResize = () => {
      /* MUESTRA EL BANNER PEQUEÑO */
      if (window.innerWidth <= 1200) {
        setShowBoton(false);
        setShowPopover(false);

      } else {

        /* MUESTRA EL BANNER GRANDE */
        setShowBoton(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setShowBoton(window.innerWidth >= 1200);
    setShowPopover(false);
  }, []);

  const [UsarBr, setUsarBr] = useState(window.innerWidth > 768);

  // useEffect para manejar el cambio de tamaño de la ventana
  useEffect(() => {
    const handleResize = () => {
      setUsarBr(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);

    // Función de limpieza para eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setUsarBr(window.innerWidth > 768);
  }, []);




  const handleContactClick = () => {
    window.location.href = "/Contacto";
  };

  // const handleClick = () => {
  //   window.location.href = '/#planes'; // Redirecciona a la página actual con el sufijo '#planes'
  //   window.location.reload(); // Recarga la página para asegurar que te desplaces al elemento con el id="planes"
  // };
  const handleClick = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      const offset = element.getBoundingClientRect().top;
      const navbar = document.getElementById("nav-header");
      const navbarHeight = navbar ? navbar.offsetHeight : 0;
      window.scrollTo({
        top: offset - navbarHeight,
        behavior: "smooth",
      });

      setTimeout(() => {
        setIsNavbarOpen(false);
      }, 100);
    }
  };




  const handleClickAcerca = () => {
    const element = document.getElementById("acerca");
    if (element) {
      const offset = element.getBoundingClientRect().top;
      const navbarHeight = 100; // Ajusta este valor según la altura de tu barra de navegación
      window.scrollTo({
        top: offset - navbarHeight,
        behavior: "smooth",
      });
    }
    // Cerramos el menú después de hacer clic
    setIsNavbarOpen(false);
  };


  const handleClickBlog = () => {
    const element = document.getElementById("blog");
    if (element) {
      const offset = element.getBoundingClientRect().top;
      const navbarHeight = 100; // Ajusta este valor según la altura de tu barra de navegación
      window.scrollTo({
        top: offset - navbarHeight,
        behavior: "smooth",
      });
    }
    // Cerramos el menú después de hacer clic
    setIsNavbarOpen(false);

  };
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen); // Cambiamos el estado del menú
  };



  return (
    <div className="contenedor-header" id="Inicio">
      {/*Video*/}
      <video tabeindex="-1" src={video} autoPlay loop muted className="video-header" />
      <div className="gradiente-video"></div>
      {[false, "sm", "md", "lg", "xl", "xxl"].map((expand, index) => (
        <Navbar
          expand="xl"
          key={index}
          data-bs-theme="dark"
          className="nav-header"
          expanded={isNavbarOpen}
          onToggle={handleNavbarToggle}
        // id="nav-header"
        >
          <Container fluid className="nav-contenedor py-2">
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                width="130"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleNavbarToggle} />
            <Navbar.Offcanvas
              backdrop={false}
              show={isNavbarOpen}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              data-bs-theme="dark"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menú
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto">
                  <Nav.Link className="link-nav me-4" href="/">
                    INICIO
                  </Nav.Link>
                  <Nav.Link
                    id="link-planes"
                    className="link-nav me-4"
                    //href={showBoton ? "/#planes" : null}
                    onClick={() => handleClick("planes")}

                  >
                    NUESTROS PLANES
                  </Nav.Link>
                  {showBoton ? (
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        display: "flex",
                        position: "relative",
                        marginLeft: "-38px",
                        marginTop: "6.5px",
                      }}
                      onClick={() => setShowPopover(!showPopover)}
                      className="buttonnavbar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="8"
                        viewBox="0 0 13 7"
                        fill="none"
                      >
                        <path
                          d="M12 0.879696L7.14818 5.73151C6.7902 6.0895 6.2098 6.0895 5.85182 5.73151L1 0.879695"
                          stroke="white"
                          stroke-width="1.70677"
                          stroke-linecap="round"
                        />
                      </svg>
                    </Button>
                  ) : null}

                  <Nav.Link id="link-acerca" className="link-nav me-4" onClick={() => handleClick("acerca")}>
                    ACERCA DE NOSOTROS
                  </Nav.Link>
                  <Nav.Link className="link-nav me-4" href="/resultados">
                    RESULTADOS
                  </Nav.Link>
                  {/**
                  <Nav.Link id="link-blog" className="link-nav" onClick={() => handleClick("blog")}>
                    BLOG
                  </Nav.Link>
                   */}
                </Nav>
                <div className="flex flex-wrap flex-row" style={{ gridGap: "20px" }}>
                  <div className="d-flex">
                    <div className="icon-header me-5 flex flex-row" style={{ gridGap: "7px" }}>
                      <a href="https://www.facebook.com/people/Genea/100094922400180/" target="_blank">
                        <img src={FacebookClaro} alt="" />
                      </a>

                      <a href="https://www.instagram.com/genea.peru/" target="_blank">
                        <img src={VideoClaro} alt="" />
                      </a>

                      <a href="https://www.linkedin.com/in/genea-per%C3%BA-3593a2285/" target="_blank">
                        <img src={InClaro} alt="" />
                      </a>

                      <a
                        href="https://api.whatsapp.com/send?phone=51960645355"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </div>
                    {/* <button className="boton-css-2 py-2 px-4 " onClick={openCalendlyPopup}>Reserva tu Cita</button> */}
                  </div>
                  <div className="d-flex gap-3"  >
                    <a className="boton-css-2 py-2 px-4 " target="_blank" href="https://calendly.com/citasgenea/30min">Reserva tu Cita</a>
                    <button className="boton-css-2 py-2 px-4  " onClick={handleContactClick}>Contacto</button>
                  </div>
                </div>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
        <div className="slider-home">
          <div className="container-header">
            <Container className="container-header-2">
              <h2 className="titulo-header titulo5">
                Desbloquea los secretos
                <br />
                de tu <span className="color-header">Código Genético</span>
                <br />
              </h2>
              <p className="parrafo">
                Desbloquea los secretos de tu código genético <br />
                Conoce hoy, antes de desarrollar la enfermedad mañana.
                <br />
                {/*salud, herencia y bienestar.*/}
              </p>
              <div>
                <a className="boton-css-2 py-2 px-4 " target="_blank" href="https://calendly.com/citasgenea/30min">Reserva tu Cita</a>
              </div>
            </Container>
            <Container>
              
              <video width={"100%"} src={video_genea} height={"auto"} controls >
                
              </video>
            </Container>
          </div>
        </div>

      <Modal
        show={AbrirModal}
        onHide={handleCloseModalReserva}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="encabezadotermi">Reserva tu cita</Modal.Title>
        </Modal.Header>
        <Modal.Body className="custom-modal-body" style={{ padding: "30px" }}>
          <p style={{ textAlign: "center", fontWeight: "700" }}>¿Qué tipo de cita desea reservar?</p>
          <a target="_blank" href="https://calendly.com/citasgenea/30min" target="__blank"><Button style={{ backgroundColor: "#172b3e", width: "110px", marginRight: "80px", marginLeft: "80px" }}>Presencial</Button></a>
          <a href="https://calendly.com/citasgenea/separa-tu-consulta-virtual" target="__blank"><Button style={{ backgroundColor: "#172b3e", width: "110px" }}>Virtual</Button></a>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalReserva} style={{ width: "100px" }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="ventana-emergente">
          <h2>eligue tu cita</h2>
          <p>Selecciona una opción:</p>
          <button onClick={() => window.location.href = 'https://calendly.com/citasgenea/separa-tu-consulta-virtual'%7D%3EVirtual</button>
          <button onClick={() => window.location.href = 'https://calendly.com/citasgenea/30min'%7D%3EPresencial</button>
          <button onClick={cerrarVentanaEmergente}>Cerrar</button>
        </div> */}

      <Overlay
        show={showPopover}
        target={document.querySelector("#link-planes")}
        placement="bottom"
        container={document.querySelector(".contenedor-header")}
        containerPadding={20}
      >
        <Popover id="popover-contained" className="popover-custom">
          <Popover.Body className="cuerpoopc">
            <span
              style={{ color: "#71CC96", paddingBottom: "2px" }}
              className="tituloplane"
            >
              Lista planes
            </span>
            <div className="principaldi">
              <div className="divsecundario">
                <a href="/Plan/Plus" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen X Plus</p>
                </a>
                {
                  /*
                   <a href="/Plan/Prime" style={{ textDecoration: "none" }}>
                    <p style={{ color: "white" }}>Plan Gen X Prime</p>
                  </a>
                   */
                }
                {/* <a href="/Plan/Ultra" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen X Ultra</p>
                </a> */}
              </div>
              {
                /*
                 <div className="divsecundario1">
                 */
              }
              {/* <a href="/Plan/Vital" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen X Vital</p>
                </a> */}
              {
                /*
                 <a href="/Plan/Ultra" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen X Ultra</p>
                </a>
                <a href="/Plan/Futuro" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen X Futuro</p>
                </a>
                */
              }
              {
                /*
                 </div>
                */
              }
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>

      {/* <div className='flex flex-wrap flex-column'>
          <div>
            <p>Lista de planes</p>
          </div>
          <div className='flex flex-row flex-wrap'>
            <div className='flex flex-wrap flex-column'>
              <a href="">Plan Gen Plus</a>
              <a href="">Plan Gen Prime</a>
              <a href="">Plan Gen Ultra</a>
            </div>
            <div className='flex flex-wrap flex-column'>
              <a href="">Plan Gen Vital</a>
              <a href="">Plan Gen Futuro</a>
            </div>
          </div>
        </div> */}
    </div >
  );
}

export default Header;