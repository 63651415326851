import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import realiza from '../videos/realiza.mp4'
import importancia from '../videos/importancia.mp4'
import beneficios from '../videos/beneficios.mp4'
import "../index.css";



function Secuencia() {
  const [isHovered, setIsHovered] = useState(false)
  const [isHovered1, setIsHovered1] = useState(false)
  const [isHovered2, setIsHovered2] = useState(false)

  const handleHover = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleHover1 = () => {
    setIsHovered1(true)
  }

  const handleMouseLeave1 = () => {
    setIsHovered1(false)
  }

  const handleHover2 = () => {
    setIsHovered2(true)
  }

  const handleMouseLeave2 = () => {
    setIsHovered2(false)
  }

  return (
    <div className="margin-t">
      <Container>
        <Row className="row-secuencia">
          <Col lg={6}>
            <p className="parrafo-secuencia">
              <p>ESTO ES IMPORTANTE PARA PERSONAS QUE TIENEN O HAN TENIDO PARIENTES DE PRIMER Y SEGUNDO NIVEL CON LAS SIGUIENTES ENFERMEDADES
                <p>Parkinson, Alzeheimer, Cáncer, etc.</p>
              </p>
            </p>
          </Col>
          <Col lg={6} className='flex borderrr justify-content-center align-items-center'>
            <div className="">
              <p className="parrafo-secuencia">
                Mediante la secuenciación de tu

                genoma, determinamos la composición

                genética completa de tu organismo o

                célula, especialmente para encontrar

                cambios en tu ADN.

                Este estudio nos ayuda a los científicos

                a pronosticar qué enfermedades

                puedes desarrollar.
              </p>
              <a href="/" className="link-secuencia">
              </a>
              {/*<><a href="/" className="link-secuencia">
                Mas
  </a></>*/}


            </div>

          </Col>
        </Row>
      </Container>
      <Container className="mt-2">
        {/**
        <a href="https://calendly.com/citasgenea/30min/"
          target="_blank"
          rel="noopener noreferrer"
          className='boton2'>
          Separa una consulta gratuita
        </a>
         */}
        <div className="gradient-video">
          {isHovered ? (
            <video src={realiza} autoPlay loop muted className="video-secuencia" />
          ) : isHovered1 ? (
            <video src={importancia} autoPlay loop muted className="video-secuencia" />
          ) : isHovered2 ? (
            <video src={beneficios} autoPlay loop muted className="video-secuencia" />
          ) : (
            <video src={realiza} autoPlay loop muted className="video-secuencia" />
          )}
          <div className="gradient-overlay" />
          <div className="video-content">
            <Row className="col-hover-2">
              <Col
                lg={4}
                className="col-hover borderrr d-flex flex-column justify-content-center"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
              >
                <h3 className="titulo-sec">¿Cómo se realiza?</h3>
                <div className="mostrar">
                  <p className="parrafo-sec">
                    Usamos la Novaseq X Series, una nueva tecnología coreana que permite niveles más altos de precisión
                    a gran escala, con la capacidad de secuenciar más de 20.000 genomas al año que causan el 95% de
                    enfermedades genéticas.
                  </p>
                  <div className="boton-secuencia d-flex justify-content-center">
                    {/* <a href="/" rel="noopener noreferrer">
                      <i className="fa-solid fa-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
              </Col>
              <Col
                lg={4}
                className="col-hover border d-flex flex-column justify-content-center"
                onMouseEnter={handleHover1}
                onMouseLeave={handleMouseLeave1}
              >
                <h3 className="titulo-sec">Importancia</h3>
                <div className="mostrar-2">
                  <p className="parrafo-sec">
                    Al secuenciar el genoma, podemos identificar y mapear la secuencia precisa de los genes y las
                    regiones no codificantes del ADN. Esto nos permite comprender mejor la estructura y función de los
                    genes, cómo interactúan entre sí y cómo influyen en el desarrollo y funcionamiento de los
                    organismos.
                  </p>
                  <div className="boton-secuencia d-flex justify-content-center">
                    {/* <a href="/" rel="noopener noreferrer">
                      <i className="fa-solid fa-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
              </Col>
              <Col
                lg={4}
                className="col-hover borderrr d-flex flex-column justify-content-center"
                onMouseEnter={handleHover2}
                onMouseLeave={handleMouseLeave2}
              >
                <h3 className="titulo-sec">Beneficios</h3>
                <div className="mostrar-3">
                  <p className="parrafo-sec">
                    Al secuenciar tu genoma, se pueden identificar variantes genéticas asociadas con enfermedades
                    hereditarias o de predisposición genética. Esto te permite tomar medidas preventivas para reducir el
                    riesgo de desarrollar enfermedades, como ajustar tu estilo de vida, someterte a pruebas de detección
                    regularmente o tomar decisiones informadas sobre opciones de tratamiento y cuidado.
                  </p>
                  <div className="boton-secuencia d-flex justify-content-center">
                    {/* <a href="/" rel="noopener noreferrer">
                      <i className="fa-solid fa-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Secuencia
