import React, { useEffect, useState } from "react";
import "../index.css";
import "../PlanComponent.css";
import Banner_plan02 from "../images/banner/Banner_plan02.jpg";
import bannerMovil2 from "../images/bannerMovil/bannerMovil2.png";
import PlanComponent from "./PlanComponent";
import ListaEnfermedad from "./enfermedad";
import iconoPeque01 from "../images/iconos/iconoPeque01.svg";
import iconoPeque02 from "../images/iconos/iconoPeque02.svg";
import iconoPeque03 from "../images/iconos/iconoPeque03.svg";
import iconoPeque04 from "../images/iconos/iconoPeque04.svg";
import iconoPeque05 from "../images/iconos/iconoPeque05.svg";
import iconoPeque06 from "../images/iconos/iconoPeque06.svg";
import iconoPeque07 from "../images/iconos/iconoPeque07.svg";
import Group02 from "../images/logos/Group02.svg";
import Grupo02 from "../images/logos/Grupo02.svg";
import flecha from "../images/flecha.png";
import { Button } from "react-bootstrap";
function PlanPlus(props) {
    const [Consultar, setConsultar] = useState(window.innerWidth >= 260);
    useEffect(() => {
        const handleResize = () => {
            setConsultar(window.innerWidth >= 260);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setConsultar(window.innerWidth >= 260);
    }, []);

    const enfermedades = () => {
        return (
            <div className="flex flex-nowrap flex-column">
                <div>
                    <p className='ListaDeEnfermedades'>LISTA DE ENFERMEDADES</p>
                </div>
                <div className="EnfermedadCuadro">
                    {/* <div className="flex flex-wrap">
                        <div className="flex flex-wrap flex-column">
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de mama o próstata"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de ovario o testículo"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de colon"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Cardiomiopatía hipertrófica"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Cardiomiopatía dilatada"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Cardiomiopatía restrictiva"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Poliposis adenomatosa familiar"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de tiroides medular familiar"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque02}
                                tituloEnfermedad="Neurofibromatosis tipo 2"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad='Síndrome de tumor de hamartoma PTEN'
                                tituloEnfermedad2=" (Nefroblastoma)"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Poliposis asociada a MUTYH"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Hipercolesterolemia familiar"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque03}
                                tituloEnfermedad="Enfermedad de Wilson"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque01}
                                tituloEnfermedad="Hipertermia Maligna"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque04}
                                tituloEnfermedad="Porfiria Aguda Intermitente"
                            />

                        </div>
                        <div className="flex flex-wrap flex-column">
                            <ListaEnfermedad
                                imgIcono={iconoPeque07}
                                tituloEnfermedad="Telangiectasia hemorrágica hereditaria"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT largo 1"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT largo 2"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT largo 3"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT corto"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Hemocromatosis hereditaria"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de estómago"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Leucemia aguda"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Síndrome mielodisplásico"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de páncreas"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Síndrome de Lynch"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome de Brugada"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de pulmón"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque02}
                                tituloEnfermedad="Alzheimer"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque02}
                                tituloEnfermedad="Parkinson"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Melanoma"
                            />
                        </div>
                    </div> */}
                    <div className="flex flex-wrap">
                        <div className="flex flex-wrap flex-column">
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de mama o próstata"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de ovario o testículo"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de colon"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Cardiomiopatía hipertrófica"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Cardiomiopatía dilatada"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Cardiomiopatía restrictiva"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Poliposis adenomatosa familiar"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de tiroides medular familiar"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque02}
                                tituloEnfermedad="Neurofibromatosis tipo 2"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad='Síndrome de tumor de hamartoma PTEN'
                                tituloEnfermedad2=" (Nefroblastoma)"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Poliposis asociada a MUTYH"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Hipercolesterolemia familiar"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque03}
                                tituloEnfermedad="Enfermedad de Wilson"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque01}
                                tituloEnfermedad="Hipertermia Maligna"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque04}
                                tituloEnfermedad="Porfiria Aguda Intermitente"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque07}
                                tituloEnfermedad="Telangiectasia hemorrágica hereditaria"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT largo 1"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT largo 2"
                            />

                        </div>
                        <div className="flex flex-wrap flex-column">

                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT largo 3"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome del QT corto"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Hemocromatosis hereditaria"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de estómago"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Leucemia aguda"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Síndrome mielodisplásico"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de páncreas"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Síndrome de Lynch"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque06}
                                tituloEnfermedad="Síndrome de Brugada"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Cáncer de pulmón"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque02}
                                tituloEnfermedad="Alzheimer"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque02}
                                tituloEnfermedad="Parkinson"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Melanoma"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque03}
                                tituloEnfermedad="Enfermedad de pompe"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Retinoblastoma"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque05}
                                tituloEnfermedad="Síndrome de poliposis juvenil"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque03}
                                tituloEnfermedad="Complejo de esclerosis tuberosa"
                            />
                            <ListaEnfermedad
                                imgIcono={iconoPeque04}
                                tituloEnfermedad="Diabetes monogénica"
                            />
                        </div>
                    </div>

                    <div>
                        <a href="https://api.whatsapp.com/send?phone=51960645355"
                            target="_blank"
                            rel="noopener noreferrer">
                            <Button
                                className={Consultar ? "PlanComponentBoton01" : ""}
                                style={{ backgroundColor: "#18C569", border: "0px" }}
                            >
                                {Consultar ? "Consulta por este plan" : "Consultar"} <img src={flecha} alt="" style={{marginLeft:"6px"}}/>
                            </Button>
                        </a>
                        <div className="flex justify-content-center">
                        </div>
                    </div>
                    <div className="flex justify-content-center">
                        <a href="https://calendly.com/citasgenea/30min/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className='botonfuturo'>
                                Reserva tu cita
                                </a>
                </div> 
                </div>
            </div>
        );
    };

    const descripcion = () => {
        return (
            <div>
                <p style={UsarBr ? null : { textAlign: "justify" }}>Nuestro plan cuenta con <b>36 enfermedades</b> {UsarBr ? (<br />) : null} por ser analizadas.</p>
            </div>
        )
    }
    const styles = {
        miComponente: {
            marginTop: "50px",
        },
    };

    const [UsarBr, setUsarBr] = useState(window.innerWidth > 445);

    // useEffect para manejar el cambio de tamaño de la ventana
    useEffect(() => {
        const handleResize = () => {
            setUsarBr(window.innerWidth > 445);
        };

        window.addEventListener('resize', handleResize);

        // Función de limpieza para eliminar el event listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setUsarBr(window.innerWidth > 445);
    }, []);

    return (
        <div id="prime">
            <PlanComponent
                banner={Banner_plan02}
                banner01={bannerMovil2}
                titulo={Grupo02}
                enfermedades={enfermedades()}
                logo={Group02}
                descripcion={descripcion()}
                linkPlan="/Plan/Prime/#prime"
                estilo={styles.miComponente}
            />
        </div>
    );
}

export default PlanPlus;
