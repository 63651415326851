import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import SwiperCore, { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper'
import icon1 from '../images/icono1.png'
import icon2 from '../images/icono2.png'
import icon3 from '../images/icono3.png'
import icon4 from '../images/icono4.png'
import icon5 from '../images/icono5.png'

SwiperCore.use([EffectCoverflow, Pagination, Navigation, Autoplay])

const Elegir = () => {

  const [UsarBr, setUsarBr] = useState(window.innerWidth >= 816);
  useEffect(() => {
    const handleResize = () => {
      setUsarBr(window.innerWidth >= 816);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setUsarBr(window.innerWidth >= 816);
  }, []);

  {/*-------------------------------]*/}
  return (
    <div style={{marginTop:"25px"}}>
      <div className="text-center">
        <h2 className="titulo-elegir">¿POR QUÉ ELEGIRNOS?</h2>
        <p className={UsarBr ? "parrafo-planes" : "parrafo-planes01"}>
          Somos un laboratorio molecular creado con la misión de
          mejorar la salud de las personas con ética y pasión. Formamos {UsarBr ? (<br />) : null}
          parte del grupo ROMACORP, asociación empresarial con
          más de 10 años en el sector salud. Nuestra visión es llegar al{UsarBr ? (<br />) : null}
          2026 siendo el laboratorio referente en la industria por su
          confiabilidad y calidad certificada.
        </p>
      </div>
      <Swiper grabCursor={true} autoplay={{ delay: 3000 }} centeredSlides={true} slidesPerView={'auto'} loop={true} className="mt-5">
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center mb-3">
              <img src={icon1} alt="icon1" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Experiencia y conocimiento</p>
              <p className="parrafo-beneficios">
                Contamos con un equipo de expertos en genómica con una amplia experiencia en la interpretación de datos
                genéticos y análisis bioinformáticos.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
       
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon2} alt="icon2" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Resultados confiables</p>
              <p className="parrafo-beneficios">
                Nuestros análisis genómicos son rigurosos y cumplen con altos estándares de calidad, lo que garantiza
                resultados confiables y precisos.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon3} alt="icon3" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Tecnología de vanguardia</p>
              <p className="parrafo-beneficios">
                Utilizamos las últimas tecnologías de secuenciación genómica para ofrecer resultados precisos y
                confiables.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon4} alt="icon3" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Confidencialidad y privacidad</p>
              <p className="parrafo-beneficios">
                Respetamos la confidencialidad y la privacidad de los datos genéticos de nuestros clientes, garantizando
                la seguridad de la información en todo momento.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon5} alt="icon4" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Precisión en la interpretación</p>
              <p className="parrafo-beneficios">
                Nuestro equipo de expertos está altamente capacitado en la interpretación de datos genómicos y ofrece
                análisis precisos y detallados para comprender las implicaciones clínicas de las variantes genéticas.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center mb-3">
              <img src={icon1} alt="icon1" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Experiencia y conocimiento</p>
              <p className="parrafo-beneficios">
                Contamos con un equipo de expertos en genómica con una amplia experiencia en la interpretación de datos
                genéticos y análisis bioinformáticos.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon2} alt="icon2" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Resultados confiables</p>
              <p className="parrafo-beneficios">
                Nuestros análisis genómicos son rigurosos y cumplen con altos estándares de calidad, lo que garantiza
                resultados confiables y precisos.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon3} alt="icon3" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Tecnología de vanguardia</p>
              <p className="parrafo-beneficios">
                Utilizamos las últimas tecnologías de secuenciación genómica para ofrecer resultados precisos y
                confiables.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon4} alt="icon3" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Confidencialidad y privacidad</p>
              <p className="parrafo-beneficios">
                Respetamos la confidencialidad y la privacidad de los datos genéticos de nuestros clientes, garantizando
                la seguridad de la información en todo momento.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon5} alt="icon4" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Precisión en la interpretación</p>
              <p className="parrafo-beneficios">
                Nuestro equipo de expertos está altamente capacitado en la interpretación de datos genómicos y ofrece
                análisis precisos y detallados para comprender las implicaciones clínicas de las variantes genéticas.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center mb-3">
              <img src={icon1} alt="icon1" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Experiencia y conocimiento</p>
              <p className="parrafo-beneficios">
                Contamos con un equipo de expertos en genómica con una amplia experiencia en la interpretación de datos
                genéticos y análisis bioinformáticos.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon2} alt="icon2" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Resultados confiables</p>
              <p className="parrafo-beneficios">
                Nuestros análisis genómicos son rigurosos y cumplen con altos estándares de calidad, lo que garantiza
                resultados confiables y precisos.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon3} alt="icon3" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Tecnología de vanguardia</p>
              <p className="parrafo-beneficios">
                Utilizamos las últimas tecnologías de secuenciación genómica para ofrecer resultados precisos y
                confiables.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon4} alt="icon3" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Confidencialidad y privacidad</p>
              <p className="parrafo-beneficios">
                Respetamos la confidencialidad y la privacidad de los datos genéticos de nuestros clientes, garantizando
                la seguridad de la información en todo momento.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="caja1">
          <div className="contenedor-elegir-2 ms-5">
            <div className="d-flex justify-content-center my-3">
              <img src={icon5} alt="icon4" className="img-fluid" />
            </div>
            <div className="text-center">
              <p className="titulo-beneficios">Precisión en la interpretación</p>
              <p className="parrafo-beneficios">
                Nuestro equipo de expertos está altamente capacitado en la interpretación de datos genómicos y ofrece
                análisis precisos y detallados para comprender las implicaciones clínicas de las variantes genéticas.
              </p>
              {/* <a href="/" className="link-beneficios">
                VER MÁS
              </a> */}
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Elegir
