
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/swiper-bundle.css';
import image1 from "../images/propuesta1.jpg";
/*
import image2 from "../images/Propuesta5.jpg";
import image3 from "../images/propuesta3.jpg";
import image4 from "../images/SLIDE_4.jpg";
import image5 from "../images/SLIDE_5.jpg";
*/
// import FlechaIzquierda from "../images/flecha-izquierda.png";
// import FlechaDerecha from "../images/flecha-correcta.png";
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
SwiperCore.use([Autoplay, Navigation, Pagination]);
function Planes() {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  // const [UsarBr, setUsarBr] = useState(true);
  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 650) {
  //       setUsarBr(false);
  //     }

  //     /* MUESTRA EL BANNER GRANDE */
  //     else {
  //       setUsarBr(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  const [UsarBr, setUsarBr] = useState(window.innerWidth >= 650);
  useEffect(() => {
    const handleResize = () => {
      setUsarBr(window.innerWidth >= 650);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setUsarBr(window.innerWidth >= 650);
  }, []);




  useEffect(() => {
    const handleWindowResize = () => {
      setShouldRedirect(window.innerWidth <= 1000);
    };

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);


  const handleImageClick = (link) => {
    if (shouldRedirect) {
      window.location.href = link;
    }
  };

  const planes = [
    {
      image: image1,
      title: "GENEA TOTAL PLUS",
      subtitle: "Salud Genética y Bienestar Integral a Tu Alcance.",
      subtitle1: "Pruebas genéticas y análisis clínicos, diseñado para ofrecerte",
      subtitle2: "un seguimiento proactivo de tu salud ahora y en el futuro",
      link: "/Plan/Plus",
    },
    /* {
       image: image2,
       title: "PLAN GEN X PRIME",
       subtitle: "Nuestro segundo plan cuenta con 23 enfermedades por ser analizadas.",
       link: "/Plan/Prime",
     },
     {
       image: image3,
       title: "PLAN GEN X FUTURO",
       subtitle: "Este plan nos permite identificar potenciales enfermedades provocadas",
       subtitle1: "por genes recesivos. Las enfermedades recesivas se heredan a la",
       subtitle2: "siguiente generación, mas no se desarrollan en el portador (padres)",
       link: "/Plan/Futuro",
     },
     {
       image: image4,
       title: "PLAN GEN X ULTRA",
       subtitle: "Nuestro tercer plan cuenta con 36 enfermedades por ser analizadas.",
       link: "/Plan/Ultra",
 
     },
      {
        image: image5,
        title: "PLAN GEN X VITAL",
        subtitle: "Nuestro Plan Gen X vital es exclusivo para menores de 30 años.",
        subtitle1: "Dentro de ello el plan vital comprende todas las enfermedades ",
        subtitle2: "del plan ultra + 5 enfermedades más.  ",
        link: "/Plan/Vital",
      },*/
  ];



  return (
    <div className="my-5" id="planes">
      <div className="text-center mb-5">
        <h2 className="titulo-planes">NUESTROS PLANES</h2>
        <p className={UsarBr ? "parrafo-planes" : "parrafo-planes01"}>
          Te invitamos a explorar nuestras opciones y descubrir cómo
          prevenir futuras enfermedades {UsarBr ? (<br />) : null} genéticas. ¡Tu viaje hacia una
          salud personalizada comienza aquí!
        </p>
      </div>
      <Swiper
        //slidesPerView={shouldRedirect ? (UsarBr ? 1.4 : 1) : 1.7}//
        slidesPerView={'auto'}
        centeredSlides={true}
        autoplay={{ delay: 3000 }}
        spaceBetween={0}
        className="mySwiper"
        loop={true}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }}
        pagination={{ clickable: true }}
        grabCursor={true}

      >
        {planes.map((plan, index) => (
          <SwiperSlide key={index}>
            <div className={`slider-contenedor ${shouldRedirect ? "image-container-zoomed" : "image-container"}`}>
              <img
                src={plan.image}
                alt={`Imagen ${index + 1}`}
                className={`slide-image ${shouldRedirect ? "mobile-image" : ""}`}
                onClick={() => handleImageClick(plan.link)}
                data-link={plan.link}
              // style={shouldRedirect ? {width:"180%"} : null}
              />
              <div
                style={{
                  position: "absolute",
                  top: "75%",
                  left: "10%",
                  transform: "translateY(-50%)",
                  color: "white",

                }}
              >
                <h1 className="tituloplanclick">
                  {plan.title}
                  <a href={plan.link} className="botonlinkp">
                    <svg
                      width="56"
                      height="56"
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="buttonfle"
                    >
                      <circle cx="28" cy="28" r="28" fill="#1C3149" />
                      <path
                        d="M36.7071 28.7071C37.0976 28.3166 37.0976 27.6834 36.7071 27.2929L30.3431 20.9289C29.9526 20.5384 29.3195 20.5384 28.9289 20.9289C28.5384 21.3195 28.5384 21.9526 28.9289 22.3431L34.5858 28L28.9289 33.6569C28.5384 34.0474 28.5384 34.6805 28.9289 35.0711C29.3195 35.4616 29.9526 35.4616 30.3431 35.0711L36.7071 28.7071ZM20 29H36V27H20V29Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </h1>
                <p className="subtituloplanclick">{plan.subtitle}</p>
                <p className="subtituloplanclick1">{plan.subtitle1}</p>
                <p className="subtituloplanclick2">{plan.subtitle2}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
        {/* Botones de flechas */}
        {/* <div className="swiper-button-next"><img src={FlechaIzquierda} alt="" /></div>
      <div className="swiper-button-prev"><img src={FlechaDerecha} alt="" /></div> */}
      </Swiper>
    </div>
  );
}

export default Planes;
