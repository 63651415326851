// import react from 'react';
// import NavBarGeneral from './NavBarGeneral';
// import "../index.css";
// import "../Resultados.css";
// import resultado from "../images/resultado1.png"
// import notificaciones from "../images/notificacion.png"

// function Resultados () {

//     return (
//         <div>
// <NavBarGeneral />
// <div style={{ paddingTop: "58px" }} className=''>
// <img src={resultado} alt="" style={{ width: "100%" }} />
//             </div>

//             <div className='ResultadoContenedor flex flex-wrap flex-column'>
//             <div className='ResultadoContenedor2 flex flex-wrap flex-row flex-column'>
// <div className='flex flex-wrap flex-column'>
// <p >En esta sección usted tendrá acceso a los resultados de los <br/> análisis que se realizó en nuestro laboratorio.</p>
// <p>Si Ud. no tiene un Usuario y Contraseña solicítelo llamando a<br/> nuestra central telefónica <b className='enfermedades16'>(511) 652 6328,</b> las credenciales<br/> serán enviadas al correo electrónico registrado en nuestra<br/>base de datos.</p>
// <br/>
// <div className='flex gap-4'>
// <img src={notificaciones} alt="" style={{ width: "52px", height: "53px" }} />

// <p style={{background:"#F6F6F6"}}>Si necesita ayuda para ingresar, contacte<br/> ahora con nuestro Laboratorio. <a href="link"> Click aqui</a></p>
// </div>
// </div>

// </div>

// <div></div>

// </div>
//         </div>
//     )

// }

// export default Resultados;

import React, { useEffect, useState } from "react"; import NavBarGeneral from "./NavBarGeneral";
import "../index.css";
import "../Resultados.css";
import resultado from "../images/resultado1.jpg";
import ResultadoBannerMovil from "../images/ResultadoBannerMovil.png";
import ImagenResultados from "../images/ImagenResultados.png";
import ImagenResultadosMovil from "../images/ImagenResultadosMovil.svg";
import notificaciones from "../images/notificacion.png";
import { Form } from "react-bootstrap";
import Footer from "./Footer";
import FooterMovil from "./FooterMovil";
function Resultados(props) {

//	const [showFooter, setShowFooter] = useState(true);
	// const [showBanner, setShowBanner] = useState(true);
	// useEffect(() => {
	// 	const handleResize = () => {
	// 		/* MUESTRA EL BANNER PEQUEÑO */
	// 		if (window.innerWidth <= 550) {
	// 			setShowFooter(false);
	// 		}

	// 		/* MUESTRA EL BANNER GRANDE */
	// 		else {
	// 			setShowFooter(true);
	// 		}
	// 	};

	// 	window.addEventListener("resize", handleResize);
	// 	return () => window.removeEventListener("resize", handleResize);
	// }, []);

	// useEffect(() => {
	// 	const handleResize = () => {
	// 		/* MUESTRA EL BANNER PEQUEÑO */
	// 		if (window.innerWidth <= 1034) {
	// 			setShowBanner(false);
	// 		}

	// 		/* MUESTRA EL BANNER GRANDE */
	// 		else {
	// 			setShowBanner(true);
	// 		}
	// 	};

	// 	window.addEventListener("resize", handleResize);
	// 	return () => window.removeEventListener("resize", handleResize);
	// }, []);

	const [showBanner, setShowBanner] = useState(window.innerWidth >= 1034);
    useEffect(() => {
        const handleResize = () => {
            setShowBanner(window.innerWidth >= 1034);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setShowBanner(window.innerWidth >= 1034);
    }, []);


	const [showFooter, setShowFooter] = useState(window.innerWidth >= 550);
    useEffect(() => {
        const handleResize = () => {
            setShowFooter(window.innerWidth >= 550);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setShowFooter(window.innerWidth >= 550);
    }, []);

	return (
		<div id="resultado">
			<NavBarGeneral />
			<div className="flex flex-nowrap flex-column">
				{/* IMAGEN BANNER PC O MOVIL */}
				{showBanner ? (
					<div>
						<div style={{ paddingTop: "70px" }} className="">
							<img src={resultado} alt="" style={{ width: "100%" }} />
						</div>
					</div>
				) : (
					<div>
						<div style={{ paddingTop: "58px" }} className="">
							<img src={ResultadoBannerMovil} alt="" className="img-fluid" style={{ width: "1034px", height: "500px" }} />
						</div>
					</div>
				)}

				<div className=" flex ResultadoContenedor2 flex-wrap flex-row">
					<div className="flex flex-column flex-nowrap">
						{/* CONDICIONAL PARA IMAGEN RESULTADO O IMAGEN RESULTADO MOVIL */}
						{showBanner ? (
							<div>
								<img src={ImagenResultados} alt="" className="ImagenResultados" />
							</div>
						) : (
							<div className="flex flex-column align-items-center">
								<img src={ImagenResultadosMovil} alt="" className="ImagenResultadosMovil" />
							</div>
						)}

						{/* TEXTO */}
						<div className="flex flex-wrap flex-column ContenedorTextoResultados">
							<p style={{ textAlign: "justify" }}>
								En esta sección usted tendrá acceso a los resultados de los
								análisis que se realizó en nuestro laboratorio.
							</p>
							<p style={{ textAlign: "justify" }}>
								Si Ud. no tiene un Usuario y Contraseña solicítelo llamando a
								nuestra central telefónica
								<b> +51 960 645 355,</b> las credenciales
								serán enviadas al correo electrónico registrado en nuestra
								base de datos.
							</p>

							<div className="flex gap-4">
								<img
									src={notificaciones}
									alt=""
									style={{ width: "52px", height: "53px"}}
								/>

								<p style={{ background: "#F6F6F6", padding:"15px", borderRadius:"10px"  }}>
									Si necesita ayuda para ingresar, contacte
									<br /> ahora con nuestro Laboratorio.{" "}
									<a target="_blank" href="https://api.whatsapp.com/send?phone=51960645355">Carlos /Servicio</a>
								</p>
							</div>
						</div>
					</div>
					<div className="flex ContenedorDelContenedor flex-nowrap flex-column round">
						{/* TITULO BIENVENIDO A RESULTADOS EN LINEA */}
						<div className="TituloContenedorResultadosBienvenido">
							<span className="BienvenidosA01">Bienvenido a </span><br /><span className="ResultadosEnlinea01">Resultados en Línea</span>
						</div>
						{/* FORMULARIO */}
						<div className="cont1 bg-white round">
							<Form>
								<Form.Group className="mb-3" controlId="validationCustom01">
									<Form.Label className="label-css font-weight-bold">
										Usuario (O código de recojo)
									</Form.Label>
									<Form.Control
										type="text"
										placeholder="Escribe aquí"
										required
										name="Nombre"
										className="custom-input"

									/>


								</Form.Group>
								<Form.Group className="mb-3" controlId="validationCustom02">
									<Form.Label className="label-css">
										Contraseña (O código de recojo)
									</Form.Label>
									<Form.Control
										type="email"
										placeholder="Escribe aquí"
										required
										name="Email"
										className="custom-input"

									/>


								</Form.Group>

								<Form.Group className="mb-2" controlId="validationCustom04">
									<Form.Check
										type="checkbox"
										label="Términos y condiciones"
										className="fuente-check"
										name="Checkbox"

									/>
								</Form.Group>
								<Form.Group>
									<Form.Label className="label-css" style={{}}>
										Cláusula informativa de <a href="linkdatos">protección de datos personales.</a>
									</Form.Label>
								</Form.Group>
								<Form.Group className='flex'>
									<Form.Label>
										He leído y acepto el tratamiento de mis datos personales para finalidades adicionales.
										<div className="d-inline-flex align-items-center">
											<Form.Check type="radio" name="exampleRadios" label="Si" id="option1" style={{ marginLeft: "10px" }} />
											<Form.Check type="radio" name="exampleRadios" label="No" id="option2" style={{ marginLeft: "10px" }} />
										</div>
									</Form.Label>
								</Form.Group>

								<Form.Group>
									<div className="d-flex justify-content-center">
										<div className="mx-auto">
											<button type="submit" className="boton">
												Ingresar
											</button>
										</div>
									</div>
								</Form.Group>
							</Form>
						</div>
					</div>

				</div>
			</div> 
			<div style={{marginTop:"-100px"}}>
			{showFooter ? (<Footer link="/resultados/#resultado" />) : <FooterMovil link="/resultados/#resultado" />}

			</div>
		</div>
	);
}

export default Resultados;
