// import React, { useState , useEffect } from 'react'
// import { BrowserRouter as Router, Routes, Route, Navigate , Link } from 'react-router-dom'
// import Header from './components/Header'
// import Secuencia from './components/Secuencia'
// import Planes from './components/Planes'
// import Elegir from './components/Elegir'
// import Noticias from './components/Noticias'
// import Network from './components/Network'
// import Partners from './components/Partners'
// import Footer from './components/Footer'
// import Content from './components/Content'
// import Articulos from './components/Articulos'
// import Articulo from './components/Articulo'
// import Login from './components/Login'
// import NavbarPanel from './components/NavbarPanel'
// import InsertarArticulo from './components/InsertarArticulo'
// import Bienvenida from './components/Bienvenido'
// import ActualizarUsuario from './components/ActualizarUsuario'
// import BlogAdmin from './components/blogAdmin'
// import EditarArt from './components/EditarBlog'
// import PlanComponent from './components/PlanComponent'
// import PlanPlus from './components/PlanPlus'
// import Planfuturo from "./components/Planfuturo"
// import Planprime from "./components/Planprime"
// import Planultra from "./components/Planultra"
// import PlanVital from "./components/PlanVital"
// import Resultados from './components/Resultados'


// import AcercaNosotros from "./components/AcercaNosotros"
// import NuestrasCertificaciones from './components/NuestrasCertificaciones'
// import Paginaprincipal from './components/Paginaprincipal'

// function App() {

//   useEffect(() => {
//     verificarPlanes();
//     verificarAcercaNosotros();
//   }, []);
//   const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true')

//   const logout = () => {
//     localStorage.removeItem('loggedIn')
//     setLoggedIn(false)
//   }

//   const verificarPlanes = () => {
//     if (window.location.hash === '#planes') {
//       const linkPlanes = document.getElementById('planes');
//       linkPlanes && linkPlanes.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   setTimeout(()=>  verificarPlanes(),500)

//   const verificarAcercaNosotros = () => {
//     if (window.location.hash === '#AcercaNosotros') {
//       const linkAcerca = document.getElementById('AcercaNosotros');
//       linkAcerca && linkAcerca.scrollIntoView({ behavior: 'smooth' });
//     }
//   };



//   setTimeout(()=>  verificarAcercaNosotros(),500)

//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/"
//           element={[
//             <Paginaprincipal key={'paginaPrincipal'}/>
//           ]}
//         />
//         <Route
//           path="/blog"
//           element={[
//             <Header key={'header'} />,
//             <Articulos key={'articulos'} />,
//             <div className="sep margin-t" key={'sep'}></div>,
//             <Footer key={'footer'} />,
//           ]}
//         />

// <Route
//           path="/resultados"
//           element={[

//          <Resultados />,
//             <div className="sep margin-t" key={'sep'}></div>,

//           ]}
//         />
//         <Route
//           path="/blog/articulo/:id"
//           element={[
//             <Header key={'header'} />,
//             <Articulo key={'articulo'} />,
//             <div className="sep margin-t" key={'sep'}></div>,
//             <Footer key={'footer'} />,
//           ]}
//         />
//         <Route path="/auth/login" element={[<Login key={'login'} setLoggedIn={setLoggedIn} />]} />
//         <Route
//           path="/admin/panel"
//           element={
//             loggedIn ? (
//               <div>
//                 <Panel key={'navbarpanel'} logout={logout} />
//                 <Bienvenida key={'bienvenida'} />
//               </div>
//             ) : (
//               <Navigate to="/auth/login" />
//             )
//           }
//         />
//         <Route
//           path="/admin/panel/articulos"
//           element={
//             loggedIn ? (
//               <div>
//                 <NavbarPanel key={'navbarpanel'} logout={logout} />
//                 <InsertarArticulo key={'insertar'} />
//               </div>
//             ) : (
//               <Navigate to="/auth/login" />
//             )
//           }
//         />
//         <Route
//           path="/admin/user/edit/:id"
//           element={
//             loggedIn ? (
//               <div>
//                 <NavbarPanel key={'navbarpanel'} logout={logout} />
//                 <ActualizarUsuario key={'actualizar'} />
//               </div>
//             ) : (
//               <Navigate to="/auth/login" />
//             )
//           }
//         />
//         <Route
//           path="/admin/panel/blog"
//           element={
//             loggedIn ? (
//               <div>
//                 <NavbarPanel key={'navbarpanel'} logout={logout} />
//                 <BlogAdmin key={'insertar'} />
//               </div>
//             ) : (
//               <Navigate to="/auth/login" />
//             )
//           }
//         />
//         <Route
//           path="/admin/panel/EditarBlog/:id"
//           element={
//             loggedIn ? (
//               <div>
//                 <NavbarPanel key={'navbarpanel'} logout={logout} />
//                 <EditarArt key={'insertar'} />
//               </div>
//             ) : (
//               <Navigate to="/auth/login" />
//             )
//           }
//         />

//         <Route
//           path="/admin/panel/EliminarBlog/:id"
//           element={
//             loggedIn ? (
//               <div>
//                 <NavbarPanel key={'navbarpanel'} logout={logout} />
//                 <EditarArt key={'insertar'} />
//               </div>
//             ) : (
//               <Navigate to="/auth/login" />
//             )
//           }
//         />
//         <Route
//           path="/webinar"
//           element={
//             <div className="contenedor" key={'contenedor'}>
//               <Content key={'content'} />
//             </div>
//           }
//         />
//         <Route
//           path="/planes"
//           element={
//             <div key={'contenedor'}>
//               <PlanComponent key={'content'} />
//             </div>
//           }
//         />
//         <Route
//           path="/Plan/Plus"
//           element={
//             <div key={'contenedor'}>
//               <PlanPlus key={'content'} />
//             </div>
//           }
//         />
//           <Route
//           path="/Plan/Ultra"
//           element={
//             <div key={'contenedor'}>
//               <Planultra key={'content'} />
//             </div>
//           }
//         />
//           <Route
//           path="/Plan/Futuro"
//           element={
//             <div key={'contenedor'}>
//               <Planfuturo key={'content'} />
//             </div>
//           }
//         />
//           <Route
//           path="/Plan/Vital"
//           element={
//             <div key={'contenedor'}>
//               <PlanVital key={'content'} />
//             </div>
//           }
//         />
//           <Route
//           path="/Plan/Prime"
//           element={
//             <div key={'contenedor'}>
//               <Planprime key={'content'} />
//             </div>
//           }
//         />
//       </Routes>
//     </Router>
//   )
// }

// export default App


import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Header from './components/Header'
import Secuencia from './components/Secuencia'
import Planes from './components/Planes'
import Elegir from './components/Elegir'
import Noticias from './components/Noticias'
import Network from './components/Network'
import Partners from './components/Partners'
import Footer from './components/Footer'
import Content from './components/Content'
import Articulos from './components/Articulos'
import Articulo from './components/Articulo'
import Login from './components/Login'
import NavbarPanel from './components/NavbarPanel'
import InsertarArticulo from './components/InsertarArticulo'
import Bienvenida from './components/Bienvenido'
import ActualizarUsuario from './components/ActualizarUsuario'
import BlogAdmin from './components/blogAdmin'
import EditarArt from './components/EditarBlog'
import PlanComponent from './components/PlanComponent'
import PlanPlus from './components/PlanPlus'
import Planfuturo from "./components/Planfuturo"
import Planprime from "./components/Planprime"
import Planultra from "./components/Planultra"
import PlanVital from "./components/PlanVital"
import Resultados from './components/Resultados'
import PageAcercaNosotros from "./components/PageAcercaNosotros"
import AcercaNosotros from "./components/AcercaNosotros"
import NuestrasCertificaciones from './components/NuestrasCertificaciones'
import Paginaprincipal from './components/Paginaprincipal'
import Contacto from './components/Contacto'
import NavBarGeneral from './components/NavBarGeneral'





function App() {
  const [loggedIn, setLoggedIn] = useState(localStorage.getItem('loggedIn') === 'true')

  const logout = () => {
    localStorage.removeItem('loggedIn')
    setLoggedIn(false)
  }

  const verificarPlanes = () => {
    if (window.location.hash === '#planes') {
      const linkPlanes = document.getElementById('link-planes')
      linkPlanes &&
        linkPlanes.click()
    }
  }
  setTimeout(() => verificarPlanes(), 500)

  const verificarAcerca = () => {
    if (window.location.hash === '#acerca') {
      const linkacerca = document.getElementById('link-acerca')
      linkacerca &&
        linkacerca.click()
    }
  }
  setTimeout(() => verificarAcerca(), 500)

  const verificarBlog = () => {
    if (window.location.hash === '#blog') {
      const linkBlog = document.getElementById('link-blog')
      linkBlog &&
        linkBlog.click()
    }
  }
  setTimeout(() => verificarBlog(), 500)
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={[
            <Paginaprincipal key={'paginaPrincipal'} />
          ]}
        />
        {/* <Route
          path="/blog"
          element={[
            <Articulos key={'articulos'} />,
          ]}
        /> */}

        <Route
          path="/resultados"
          element={[

            <Resultados />,
            <div key={'sep'}></div>,

          ]}
        />
        <Route
          path="/blog/articulo/:id"
          element={[
            <Articulo key={'articulo'} />,
            <div key={'sep'}></div>,
            <Footer key={'footer'} />,
          ]}
        />
        <Route path="/auth/login" element={[<Login key={'login'} setLoggedIn={setLoggedIn} />]} />
        <Route
          path="/admin/panel"
          element={
            loggedIn ? (
              <div>
                <NavbarPanel key={'navbarpanel'} logout={logout} />
                <Bienvenida key={'bienvenida'} />
              </div>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/admin/panel/articulos"
          element={
            loggedIn ? (
              <div>
                <NavbarPanel key={'navbarpanel'} logout={logout} />
                <InsertarArticulo key={'insertar'} />
              </div>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/admin/user/edit/:id"
          element={
            loggedIn ? (
              <div>
                <NavbarPanel key={'navbarpanel'} logout={logout} />
                <ActualizarUsuario key={'actualizar'} />
              </div>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/admin/panel/blog"
          element={
            loggedIn ? (
              <div>
                <NavbarPanel key={'navbarpanel'} logout={logout} />
                <BlogAdmin key={'insertar'} />
              </div>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/admin/panel/EditarBlog/:id"
          element={
            loggedIn ? (
              <div>
                <NavbarPanel key={'navbarpanel'} logout={logout} />
                <EditarArt key={'insertar'} />
              </div>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />

        <Route
          path="/admin/panel/EliminarBlog/:id"
          element={
            loggedIn ? (
              <div>
                <NavbarPanel key={'navbarpanel'} logout={logout} />
                <EditarArt key={'insertar'} />
              </div>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/webinar"
          element={
            <div className="contenedor" key={'contenedor'}>
              <Content key={'content'} />
            </div>
          }
        />
        <Route
          path="/planes"
          element={
            <div key={'contenedor'}>
              <PlanComponent key={'content'} />
            </div>
          }
        />
        {
          /*
            <Route
            path="/Plan/Plus"
            element={
              <div key={'contenedor'}>
                <PlanPlus key={'content'} />
              </div>
            }
          />
          <Route
            path="/Plan/Ultra"
            element={
              <div key={'contenedor'}>
                <Planultra key={'content'} />
              </div>
            }
          />
          <Route
            path="/Plan/Futuro"
            element={
              <div key={'contenedor'}>
                <Planfuturo key={'content'} />
              </div>
            }
          />
          */
        }
        {/* <Route
          path="/Plan/Vital"
          element={
            <div key={'contenedor'}>
              <PlanVital key={'content'} />
            </div>
          }
        /> */}
        <Route
          path="/Plan/Plus"
          element={
            <div key={'contenedor'}>
              <Planprime key={'content'} />
            </div>
          }
        />
        <Route
          path="/Contacto"
          element={
            <div key={'contenedor'}>
              <Contacto key={'content'} />
            </div>
          }
        />
        <Route
          path="/AcercaNosotros"
          element={
            <div key={'contenedor'}>
              <PageAcercaNosotros key={'PageAcercaNosotros'} />
            </div>
          }
        />
      </Routes>
    </Router>
  )
}

export default App