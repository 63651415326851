import React from "react";
import "../index.css";

function Textoacercanosotros() {
  return (
    <div>
      <p>
        <b className="titulosmo">1. Condiciones de uso </b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        Todo usuario que ingresa al sitio web <a href="/">https://genea.com.pe/</a> se
        compromete a leer detenidamente y aceptar –si estuviera de acuerdo con
        el texto leído– los términos y condiciones generales antes de utilizar
        los portales y servicios web que ofrece la empresa. De no estar de
        acuerdo o no aceptarlos, deberá abstenerse de navegar por dicho sitio
        web y/o manipular cualquier tipo de información contenida en él, bajo
        responsabilidad propia. El propósito del sitio web es brindar
        información, así como promocionar y divulgar los servicios y/o productos
        que ofrece Medical Care Services S.A.C. (en adelante, “Genea”). Está
        dirigido a clientes y público en general.</div> 
        <br />{" "}
        <b className="titulosmo">
          2. Derechos de propiedad industrial e intelectual
        </b>{" "}
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        Todos los derechos de propiedad industrial e intelectual contenidos en
        la página web son de propiedad de GENEA. Se prohíbe el uso de cualquier
        derecho de propiedad industrial e intelectual sin contar con el
        consentimiento previo, expreso y por escrito de GENEA.
        </div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          2.1. Derechos de autor </b><br/>
        <div style={{marginTop:"10px", paddingLeft:"20px", }} className="textoacercano">
        Este sitio se encuentra protegido por la normativa peruana vigente sobre
        derechos de autor. Todos los derechos involucrados, como por ejemplo su
        contenido y su diseño visual, son de titularidad de GENEA, por lo que se
        encuentra estrictamente prohibido por parte de terceros el empleo,
        modificación, reproducción, distribución, transmisión o comercialización
        de los derechos involucrados sin el permiso previo, expreso y por
        escrito de GENEA.</div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}} >
          2.2. Signos distintivos </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}} className="textoacercano">
        Todos los signos distintivos (marcas de productos y de servicio, lemas
        comerciales y nombres comerciales) registrados de acuerdo a la
        legislación nacional y supranacional, son propiedad de GENEA. Se prohíbe
        el uso de cualquier signo distintivo de titularidad de GENEA contenido
        en el presente sitio web sin el consentimiento por escrito previo, de
        manera previa, de GENEA.{" "}</div><br/>
        <b className="titulosmo">3. Aceptación de términos</b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        Los sitios web y referencias están disponibles para que el usuario, bajo
        su responsabilidad, los utilice adecuadamente sin aprovecharse de alguna
        falla que ocurra y saque provecho de la misma. Si encuentra alguna falla
        en nuestro sitio web, el usuario se compromete a reportarla a
        GENEA.COM.PE. Queda prohibido el uso del nombre, logotipos, marcas,
        diseños o cualquier signo distintivo de autoría de GENEA, como enlaces
        de hipertexto o hipervínculos a páginas no controladas por la empresa,
        sin previa y expresa autorización GENEA. Se prohíbe igualmente incluir
        en sitios y portales no controlados por GENEA enlaces o páginas
        interiores, evitando así visitar la página principal GENEA, ubicada en
        GENEA.COM.PE. Se prohíbe igualmente el despliegue, exhibición o
        reproducción del presente sitio web, o de cualquiera de sus
        subdirectorios o páginas secundarias, en sitios o portales no
        controlados por GENEA, salvo que éste considere pertinente promocionar a
        la empresa en las redes sociales y otras tecnologías Web 2.0. Mientras
        tanto, queda prohibido que terceros utilicen el nombre comercial o los
        signos distintivos de titularidad GENEA para registrarse en alguna
        tecnología Web 2.0. El usuario se compromete a emplear de forma
        diligente y lícita todos los contenidos del presente sitio web de GENEA
        sólo con fines informativos y sin reproducir, copiar o distribuir
        información que no sea pública de otros enlaces que no incluya las
        referencias al portal de la empresa.</div><br/>
        <b className="titulosmo">4.Obligaciones de los usuarios en general </b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        El usuario se compromete a utilizar los servicios y contenidos que le
        proporciona el portal conforme a la legislación vigente, a los
        principios de buena fe y usos generalmente aceptados y a no
        contravenirlos con sus acciones a través de la web de GENEA. El usuario
        se compromete a suministrar información verdadera y exacta acerca de sí
        mismo en los formularios de registro del portal.{" "}</div><br/>
        <b className="titulosmo">5. Políticas de privacidad </b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        En MEDICAL CARE SERVICES S.A.C. (En adelante “LA EMPRESA”) nos
        preocupamos por la protección y privacidad de los datos personales de
        nuestros usuarios. Por ello, garantizamos la absoluta confidencialidad
        de los mismos y empleamos altos estándares de seguridad conforme a lo
        establecido en la Ley de Protección de Datos Personales – Ley N° 29733 y
        su Reglamento aprobado por el Decreto Supremo N° 003-2013-JUS (“las
        normas de protección de datos personales”). </div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          5.1 Tratamiento y transferencia de tus datos personales.{" "}
        </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}} className="textoacercano">
        De acuerdo a las normas de protección de datos personales, en LA EMPRESA
        estamos legalmente autorizados para usar tus datos personales de los
        usuarios, con la finalidad de ejecutar la relación contractual que
        mantienes con nosotros. También podemos usar y compartir tus datos
        personales con autoridades y terceros autorizados por la ley con la
        finalidad de cumplir con las obligaciones señaladas en las normas
        peruanas o internacionales, incluyendo las vinculadas al sistema de
        prevención de lavado de activos y normas prudenciales Tus datos
        personales incluyen la información que nos hayas proporcionado y
        aquellos datos personales, a los que podemos acceder, de forma física,
        oral o electrónica a través de fuentes accesibles al público o de
        terceros. Si lo autorizas, podremos incluir tu Información en nuestras
        bases de datos personales; almacenarla, procesarla y transferirla a
        alguna de sus subsidiarias, afiliadas y socios comerciales (las
        “Terceras Empresas”) listados en nuestra página web GENEA.COM.PE
        Asimismo podremos ofrecerte cualquiera de los productos o servicios de
        LA EMPRESA a través de cualquier medio escrito, verbal, electrónico y/o
        informático y transferir tus datos personales a alguna de las terceras
        empresas para que cada una de ellas pueda ofrecerle sus productos o
        servicios a través de cualquier medio escrito, verbal, electrónico y/o
        informático.</div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          5.2 Tratamiento de los datos personales de menores de edad{" "}
        </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}} className="textoacercano">

        “LA EMPRESA” solo utilizan los datos personales de menores de edad con
        el previo consentimiento de sus padres, tutores o representantes
        legales. Si algún menor de edad ingresa sus datos personales a través de
        nuestro sitio web, deberán solicitar el permiso correspondiente a sus
        padres, tutores o representantes legales, quienes serán considerados
        responsables de todos los actos realizados por los menores a su cargo.</div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          5.3 Uso de cookies </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}} className="textoacercano">

        Este sitio web utiliza cookies, que son pequeños archivos que se
        almacenan en las computadoras y que nos permiten recordar
        características o preferencias de la navegación que tienes en nuestra
        web. Gracias a esto podemos personalizar los ingresos a la web en
        futuras visitas, hacer más segura la navegación y conocer tus
        preferencias para ofrecerte información de tu interés. Puedes configurar
        tu navegador para aceptar o rechazar la instalación de cookies o
        suprimirlos una vez que hayas finalizado la navegación en nuestro sitio
        web. “LA EMPRESA” no se responsabiliza de que la desactivación de las
        cookies pueda impedir el buen funcionamiento de nuestra web.</div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          5.4 Ejercicio de derechos por el titular de datos personales
        </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}} className="textoacercano">
        Puedes solicitar que atendamos tus derechos de acceso, información,
        rectificación, cancelación, oposición y revocación al uso de sus datos
        personales por parte de LA EMPRESA acercándose a cualquiera de nuestros
        establecimientos a nivel nacional, en el horario establecido para la
        atención al público. No olvides que deberás presentar tu documento de
        identidad (DNI/CE/Pasaporte) para validar que eres el titular. Si
        utilizas un representante legal, este deberá estar acreditado como tal,
        es decir deberá presentar el poder o título que confirme que se
        encuentra autorizado para ejercer cualquiera de estos derechos en tu
        representación. Tú o tu representante deberán llenar la “Solicitud de
        atención de derechos protegidos – Ley de Protección de Datos Personales”
        y deberán presentar los documentos que sustenten el pedido de ser el
        caso. Atenderemos la solicitud sin costo alguno siguiendo el
        procedimiento y los plazos previstos en las normas de protección de
        datos personales DERECHO DE ACCESO / INFORMACIÓN Tienes derecho a ser
        informado sobre tus datos personales incluidos en nuestra base de datos,
        la forma que fueron recopilados, las razones que motivaron su
        recopilación y a solicitud de quien se realizó la recopilación, las
        transferencias realizadas, así como de las condiciones y uso que les
        damos. Te brindaremos e toda la información sobre la finalidad para la
        cual serán utilizados sus datos personales, quiénes tienen acceso a
        ellos, en cuáles de nuestras base de datos se almacenarán, así como
        nuestra identidad y domicilio como titulares de la base de datos y, de
        ser el caso, del encargado de gestionar sus datos personales, de la
        transferencias nacionales o internacionales que hacemos de los mismos,
        del carácter obligatorio o facultativo de las respuestas a los
        cuestionarios que entreguemos, de las consecuencias de proporcionar sus
        datos personales y de su negativa a hacerlo, del tiempo de conservación
        de los mismos y de la posibilidad de ejercer los derechos que las normas
        te conceden y los medios previstos para ello. DERECHO DE RECTIFICACIÓN /
        ACTUALIZACIÓN Puedes solicitar la actualización, inclusión o
        modificación de tus datos personales cuando tus datos sean parcial o
        totalmente inexactos, incompletos, erróneos, falsos o desactualizados.
        Deberás especificar los datos que deseas que sean rectificados/
        actualizados/ modificados / incluidos, así como la
        corrección/incorporación que quieras que la Empresa realice y adjuntar
        los documentos de sustento aplicables DERECHO DE CANCELACIÓN / SUPRESIÓN
        Puedes solicitar la supresión o cancelación total o parcial de tus datos
        personales de nuestra base de datos si han dejado de ser necesarios para
        la finalidad que fueron recopilados, si venció el plazo para su
        tratamiento o el tratamiento no se realice conforme a las normas de
        protección de datos personales. Ten en cuenta que tu solicitud de
        cancelación no procederá si mantienes una relación contractual con “LA
        EMPRESA” y tus datos sean necesarios para ejecutar dicha relación
        contractual. Tampoco procederá cuando estemos obligados a conservar tus
        datos personales durante los plazos previstos en las normas vigentes ni
        cuando sean conservados en virtud de razones históricas, estadísticas o
        científicas de acuerdo con la legislación aplicable. DERECHO DE
        OPOSICIÓN Tienes derecho a oponerte a figurar en nuestra base de datos
        personales o a la gestión de la Información cuando no hayas autorizado
        su recopilación, por haber sido tomada de fuentes de acceso al público o
        cuando habiendo prestado tu consentimiento, acredite la existencia de
        motivos fundados y legítimos relativos a una concreta situación personal
        que justifique el ejercicio de este derecho. DERECHO DE REVOCACIÓN En
        cualquier momento y sin justificación previa nos puedes pedir que tus
        datos personales dejen de ser utilizados para alguna de las finalidades
        previamente autorizadas. Ten en consideración que tu solicitud de
        revocación no procederá si mantienes una relación contractual con LA
        EMPRESA y tus datos son necesarios para ejecutar dicha relación
        contractual. Tampoco procederá cuando estemos obligados a conservar tus
        datos personales durante los plazos previstos en las normas vigentes ni
        cuando sean conservados por razones históricas, estadísticas o
        científicas de acuerdo legislación aplicable.{" "}</div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          5.5 Modificaciones a la Política de Privacidad{" "}
        </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}} className="textoacercano">
        “LA EMPRESA” se reserva el derecho a modificar esta Política de
        Privacidad en cualquier momento y sin previo aviso. Toda modificación
        entrará en vigencia y tendrá efectos frente a los terceros relacionados
        desde su publicación en este sitio web.</div><br/>
        <b className="titulosmo1" style={{paddingLeft:"20px"}}>
          5.6 Contactar a “LA EMPRESA” </b>
        <br />
        <div style={{marginTop:"10px", paddingLeft:"20px"}}className="textoacercano">
        Ten en cuenta que nuestra página web puede ofrecer links o acceso a
        otros sitios webs que no son parte de LA EMPRESA Por eso, te
        recomendamos revisar cuidadosamente las políticas de privacidad en
        dichas páginas web ya que pueden ser distintas a nuestras Políticas de
        Privacidad. Para cualquier duda relacionada con la presente Política de
        Privacidad, pueden ponerse en contacto con nosotros a través de nuestra
        central telefónica : (01) 652-6328 o acercarse a cualquiera de nuestros
        establecimientos a nivel nacional, en el horario establecido para la
        atención al público.</div> <br/>
        <b className="titulosmo">
          6. Modificaciones a los términos y condiciones
        </b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        GENEA se reserva el derecho de modificar el contenido y alcance de los
        presentes términos y condiciones en cualquier momento y según lo
        considere necesario. El usuario declara tener conocimiento de la
        presente cláusula y autoriza a GENEA a efectuar dichos términos y
        modificaciones.</div><br/>
        <b className="titulosmo">7. Términos adicionales </b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        Por separado, ciertas secciones o páginas de este sitio web pueden
        contener términos y condiciones que sean adicionales a los términos y
        condiciones que aparecen aquí. En caso de algún conflicto, los términos
        y condiciones adicionales prevalecerán en las secciones o páginas donde
        aparezcan.</div> <br/>
        <b className="titulosmo">8. Delimitación de responsabilidad</b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">
        El sitio web GENEA.COM.PE. es una página que ha sido creada únicamente
        con fines informativos. En todos los casos de discrepancia en la
        información contenida en esta página y documentos entregados a los
        clientes prevalecerán estos textos frente a los que puedan aparecen en
        la página web. GENEA no garantiza un servicio libre e ininterrumpido de
        esta página, pero sí declara su voluntad de efectuar los esfuerzos que,
        dentro de lo razonable, permitan alcanzar su disponibilidad por el mayor
        tiempo posible.</div>
        <br />
        <b className="titulosmo"> 9. Leyes vigentes</b>
         <br /> 
         <div style={{marginTop:"10px"}} className="textoacercano">
         El uso de este sitio web se regirá por todas las leyes aplicables de la República del
        Perú.</div>
        <br />
        <b className="titulosmo">10. Lista de empresas - Socios Comerciales</b>
        <br />
        <div style={{marginTop:"10px"}} className="textoacercano">

        1. Bregma S.A.C.
        <br /> 2. Yasta S.A.C.
        <br /> 2.1. Kampanee
        <br /> 3. Getbyte
        <br /> 4. Panacea SAC
        <br />4.1. Dromed
        </div>
      </p>
    </div>
  );
}

export default Textoacercanosotros;
