import React from "react";
import "../index.css";

function TextoClausulacon() {
  return (
    <div>
      <p>
        <div className="textoacercano1  ">
          Nos autorizas a utilizar tus datos personales para las siguientes
          finalidades:
          <br />
          <div style={{marginTop:"20px"}}>
          <ul>
            {" "}
            - Realizar encuestas de satisfacción para mejorar la calidad de los
            servicios que ofrecemos.
          </ul>
          <ul>
           - Enviar comunicaciones referidas a nuevos servicios y promociones.
          </ul>
          <ul>
          - Transferir sus datos personales, a nivel nacional, con la finalidad de
          cumplir con las dos finalidades adicionales antes mencionadas, a las
          empresas vinculadas y asociadas de Medical Care Services S.A.C. listadas en nuestro portal web
          como Lista de empresas – Transparencia.
          </ul>
          <div style={{marginTop:"40px"}}>
          Te recordamos que podrás acceder a esta información, rectificarla,
          cancelarla, oponerse a su uso o revocar el consentimiento,
          dirigiéndote a las sedes indicadas en <a href="/">https://genea.com.pe/.</a>
          </div>
          </div>
        </div>
       
      </p>
    </div>
  );
}

export default TextoClausulacon;
