import React, { useEffect, useState } from 'react'
import { Button, Form, Navbar } from 'react-bootstrap';
import NavBarGeneral from './NavBarGeneral';
import BannerAcercaNosotros from '../images/PageAcercaNosotros/BannerAcercaNosotros.jpg';
import GeneaAN from '../images/PageAcercaNosotros/GeneaAN.svg';
import Footer from './Footer';
import FooterMovil from './FooterMovil';
import "../PageAcercaNosotros.css";
import VideoNosotros from "../images/PageAcercaNosotros/VideoNosotros.mp4";
import LogoGenea from '../images/PageAcercaNosotros/LogoGenea.svg';
import Icono01 from '../images/PageAcercaNosotros/Icono01.svg';
import Icono02 from '../images/PageAcercaNosotros/Icono02.svg';
import Icono03 from '../images/PageAcercaNosotros/Icono03.svg';
import Icono04 from '../images/PageAcercaNosotros/Icono04.svg';
import Icono05 from '../images/PageAcercaNosotros/Icono05.svg';
import Texto01 from '../images/PageAcercaNosotros/texto01.svg';
import Texto02 from '../images/PageAcercaNosotros/texto02.svg';
import Texto03 from '../images/PageAcercaNosotros/texto03.svg';
import Texto04 from '../images/PageAcercaNosotros/texto04.svg';
import Texto05 from '../images/PageAcercaNosotros/texto05.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Navigate, useNavigate } from 'react-router-dom';
import "../index.css";
import ResultadoBannerMovil from "../images/ResultadoBannerMovil.png";
import ImagenResultadosMovil from "../images/ImagenResultadosMovil.svg";

const PageAcercaNosotros = () => {

    const [showFooter, setShowFooter] = useState(window.innerWidth >= 550);
    useEffect(() => {
        const handleResize = () => {
            setShowFooter(window.innerWidth >= 550);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setShowFooter(window.innerWidth >= 550);
    }, []);

    const [UsarBr, setUsarBr] = useState(window.innerWidth >= 600);
    const [medida01, setMedida01] = useState(window.innerWidth >= 780);
    const [FondoPlomo, setFondoPlomo] = useState(window.innerWidth >= 1615);
    useEffect(() => {
        const handleResize = () => {
            setUsarBr(window.innerWidth >= 600);
            setMedida01(window.innerWidth >= 780);
            setFondoPlomo(window.innerWidth >= 1615);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setUsarBr(window.innerWidth >= 600);
        setMedida01(window.innerWidth >= 780);
        setFondoPlomo(window.innerWidth >= 1615);
    }, []);

    const [correo, setCorreo] = useState('');
    const [mensaje, setMensaje] = useState('');

    const enviarCorreo = () => {
        fetch(process.env.REACT_APP_API_URL + `Login.php?correo`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ correo, mensaje }),
        })
            .then((response) => response.json())
            .then((data) => {
                // Aquí puedes manejar la respuesta del servidor, por ejemplo, mostrar un mensaje de éxito o error
                if (data.success) {
                    console.log('Correo enviado correctamente');
                } else {
                    console.error('Error al enviar el correo:', data.message);
                }
            })
            .catch((error) => {
                console.error('Error en la solicitud:', error);
            });
    };

    const [showBanner, setShowBanner] = useState(window.innerWidth >= 1034);
    useEffect(() => {
        const handleResize = () => {
            setShowBanner(window.innerWidth >= 1034);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setShowBanner(window.innerWidth >= 1034);
    }, []);


    return (
        <div id='Nosotros'>
            <NavBarGeneral />
            <div className='flex flex-nowrap flex-column'>
                {showBanner ? (
					<div>
						<div style={{ paddingTop: "70px" }} className="">
							<img src={BannerAcercaNosotros} alt="" style={{ width: "100%" }} />
						</div>
					</div>
				) : (
					<div>
						<div style={{ paddingTop: "58px" }} className="">
							<img src={ResultadoBannerMovil} alt="" className="img-fluid" style={{ width: "1034px", height: "250px" }} />
						</div>
					</div>
				)}
                <div className='Flotar'>
                    <p className='AcercaDe'>Acerca de <span className='Genea'>Genea</span></p>
                    <img src={GeneaAN} alt="" className='ImagenGenea' />
                    
                </div>
 
  
                <div className=''>
                    <div className='flex flex-wrap flex-row align-items-center contenedorQuienesSomos' style={{ gridGap: "30px" }}>
                        <p className='QuienesSomos'>¿QUIÉNES SOMOS?</p>
                        <p className='TextoQuienesSomos'>Somos Genea, laboratorio molecular creado con la misión de mejorar la salud de las personas con ética y pasión.
                        </p>
                    </div>

                    <div className=''>
                        {medida01 ? (<div>
                            <video src={VideoNosotros} autoPlay loop muted className="VideoNosotros" />
                            <p className='TextoVideo'>Estamos comprometidos a desentrañar los <span className='TextoVideo01'>misterios del ADN</span> <br />
                                para construir un futuro más <span className='TextoVideo01'>saludable y esperanzador.</span> </p>
                        </div>) : null}

                        <div className='flex flex-wrap flex-row contenedorGeneralMisionVision'>
                            <div className='contenedorMisionVision' style={{ gridGap: "65px" }}>
                                <div className='Verde'></div>
                                <div className='contenedorMisionVision01'>
                                    <h3 className='MisionVision'>MISIÓN</h3>
                                    <p className='TextoMisionVision'>Mejorar la salud con ética y pasión.</p>
                                </div>

                            </div>
                            <div className='contenedorMisionVision' style={{ gridGap: "15px" }}>
                                <div className='Verde'><p></p></div>
                                <div className='contenedorMisionVision01'>
                                    <p className='MisionVision'>VISIÓN</p>
                                    <p className='TextoMisionVision'>Para el 2026 tendremos marcas referentes en las industrias donde operemos identificados como una empresa confiable de calidad certificada.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {FondoPlomo ? (<div className='fondoPlomo'></div>) : null}
                    <div className='flex flex-wrap flex-column' style={{ marginTop: "120px" }}>
                        <h2 className='Valores'>VALORES</h2>
                        <div className='flex flex-wrap flex-row justify-content-center '>
                            <div className='flex flex-wrap flex-column align-items-center'>
                                <img className='IconoValores' src={Icono01} alt="" />
                                <h4 className='TituloValores'>Nos gustan los retos</h4>
                                <img className='TextoImagenValores' src={Texto01} alt="" />

                            </div>
                            <div className='flex flex-wrap flex-column align-items-center'>
                                <img className='IconoValores' src={Icono02} alt="" />
                                <h4 className='TituloValores'>Bien común</h4>
                                <img className='TextoImagenValores' src={Texto02} alt="" />
                            </div>
                            <div className='flex flex-wrap flex-column align-items-center'>
                                <img className='IconoValores' src={Icono03} alt="" />
                                <h4 className='TituloValores'>Primero el cliente</h4>
                                <img className='TextoImagenValores' src={Texto03} alt="" />
                            </div>
                            <div className='flex flex-wrap flex-column align-items-center'>
                                <img className='IconoValores' src={Icono04} alt="" />
                                <h4 className='TituloValores'>Somos honestos</h4>
                                <img className='TextoImagenValores' src={Texto04} alt="" />
                            </div>
                            <div className='flex flex-wrap flex-column align-items-center'>
                                <img className='IconoValores' src={Icono05} alt="" />
                                <h4 className='TituloValores'>Somos empáticos</h4>
                                <img className='TextoImagenValores' src={Texto05} alt="" />
                            </div>
                        </div>

                    </div>
                    <div className='flex flex-wrap flex-row ContenedorEnterate'>
                        <img className='IconoGenea' src={LogoGenea} alt="" />
                        <div>
                            <p className='EnterateNosotros'>Entérate más de nosotros</p>
                            <p className='ConocePlanes'>Conoce todos los planes y beneficios.</p>
                        </div>
                        <Form.Control
                            type="email"
                            className="inputCorreo"
                            placeholder="Correo electrónico"
                            value={correo}
                            onChange={(e) => setCorreo(e.target.value)}
                        />
                        <Button className="BotonEnviarCorreo" onClick={enviarCorreo}>
                            Enviar
                        </Button>
                    </div>
                </div>
            </div>
            {showFooter ? (<Footer link="/#Nosotros" />) : (<FooterMovil link="/#Nosotros" />)}
        </div>
    )
}

export default PageAcercaNosotros;