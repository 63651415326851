import React, { useEffect, useState } from 'react';
import "../index.css";
import ISO37001 from "../images/isos/ISO37001.png";
import ISO45001 from "../images/isos/ISO45001.png";
import ISO9001 from "../images/isos/ISO9001.png";
import ISO14001 from "../images/isos/ISO14001.png";
import ISO27001 from "../images/isos/ISO27001.png";
function NuestrasCertificaciones() {

    // const [UsarBr, setUsarBr] = useState(true);
    // useEffect(() => {
    //     const handleResize = () => {
    //         /* MUESTRA EL BANNER PEQUEÑO */
    //         if (window.innerWidth <= 890) {
    //             setUsarBr(false);
    //         }

    //         /* MUESTRA EL BANNER GRANDE */
    //         else {
    //             setUsarBr(true);
    //         }
    //     };

    //     window.addEventListener("resize", handleResize);
    //     return () => window.removeEventListener("resize", handleResize);
    // }, []);



    const [UsarBr, setUsarBr] = useState(window.innerWidth >= 820);
    useEffect(() => {
        const handleResize = () => {
            setUsarBr(window.innerWidth >= 820);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setUsarBr(window.innerWidth >= 820);
    }, []);


    return (
        <div className='flex flex-wrap flex-column margin-t'>
            <p className='NuestrasCertificacionesTitulo'>NUESTRAS CERTIFICACIONES</p>

            <p className={UsarBr ? 'NuestrasCertificacionestexto' : 'NuestrasCertificacionestexto01'}>
                Somos un laboratorio molecular dedicado a realizar pruebas genéticas en el Perú. Contamos con 5 certificaciones {UsarBr ? (<br />) : null} internacionales
                 que garantizan la transparencia  y objetividad para ejecutar correctamente los procesos en todas las etapas.
            </p>

            <div className="flex flex-wrap flex-row flex-1 ContenedorIsos01">
                <img src={ISO9001} alt="" className="imagenIsos01" />
                <img src={ISO14001} alt="" className="imagenIsos01" />
                <img src={ISO27001} alt="" className="imagenIsos01" />
                <img src={ISO37001} alt="" className="imagenIsos01" />
                <img src={ISO45001} alt="" className="imagenIsos01" />
            </div>

        </div>
    )
}

export default NuestrasCertificaciones;
