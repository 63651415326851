import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import NavBarGeneral from './NavBarGeneral'

const imageUrl = process.env.REACT_APP_API_URL + 'images/'

function Articulo() {
  const { id } = useParams()
  const [datosCargados, setDatosCargados] = useState(false)
  const [articulo, setArticulo] = useState({})

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + `articulos.php?consultar=${id}`)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        setDatosCargados(true)
        setArticulo(datosRespuesta)
      })
  }, [id])

  if (!datosCargados) {
    return (
      <div>
        {/* <h1>Error</h1> */}
      </div>
    )
  } else {
    const lineasContenido = articulo.contenido.split('\n')

    return (
      <div className=''>
        <NavBarGeneral/>
        <div className="flex justify-content-center align-items-center" style={{paddingTop:"150px", }}>

          <div key={articulo.id} className="blog-articulo" >
            <h1 className="text-center mb-5 fw-bold" >{articulo.titulo}</h1>
            {lineasContenido.map((linea, index) => (
              <p key={index}>{linea}</p>
            ))}
            <div className="d-flex justify-content-center">
              <img src={imageUrl + articulo.imagen} alt="imagen" width="600" className="img-fluid mt-3" />
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Articulo