import React, { useState, useEffect } from "react";
import logo from "../images/logo.png";
import video from "../videos/adn.mp4";
import InClaro from "../images/contacto/InClaro.png";
import VideoClaro from "../images/contacto/VideoClaro.png";
import FacebookClaro from "../images/contacto/FacebookClaro.png";
import {
  Button,
  Container,
  Modal,
  Nav,
  Navbar,
  Offcanvas,
  Overlay,
  Popover,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import vector from "../images/Vector.png";
import "../index.css";

function NavBarGeneral({ handleScrollPlanes }) {
  // const [showPopover, setShowPopover] = useState(false);
  // const [showBoton, setShowBoton] = useState(true);


  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 1200) {
  //       setShowBoton(false);
  //       setShowPopover(false);

  //     } else {

  //       /* MUESTRA EL BANNER GRANDE */
  //       setShowBoton(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);


  const [showPopover, setShowPopover] = useState(window.innerWidth >= 1200);
  const [showBoton, setShowBoton] = useState(window.innerWidth >= 1200);
  useEffect(() => {
    const handleResize = () => {
      /* MUESTRA EL BANNER PEQUEÑO */
      if (window.innerWidth <= 1412) {
        setShowBoton(false);
        setShowPopover(false);

      } else {

        /* MUESTRA EL BANNER GRANDE */
        setShowBoton(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setShowBoton(window.innerWidth >= 1200);
    setShowPopover(false);
  }, []);

  const handleContactClick = () => {
    window.location.href = "/Contacto";
  };

  const reservaTuCita = () => {
    window.open(
      'https://calendly.com/citasgenea/30min',
      'Calendly',
      'width=800,height=600'
    );
  };

  return (
    <div id="Inicio">
      {[false, "sm", "md", "lg", "xl", "xxl"].map((expand, index) => (
        <Navbar
          expand="xl"
          key={index}
          data-bs-theme="dark"
          className="nav-header"
        >
          <Container fluid className="nav-contenedor py-2">
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="logo"
                className="img-fluid"
                width="130"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              data-bs-theme="dark"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Menú
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="me-auto">
                  <Nav.Link className="link-nav me-4" href="/">
                    INICIO
                  </Nav.Link>
                  <Nav.Link
                    id="link-planes"
                    className="link-nav me-4"
                    href="/#planes"
                  >
                    NUESTROS PLANES
                  </Nav.Link>

                  {showBoton ? (
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        display: "flex",
                        position: "relative",
                        marginLeft: "-38px",
                        marginTop: "6.5px",
                      }}
                      onClick={() => setShowPopover(!showPopover)}
                      className="buttonnavbar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="8"
                        viewBox="0 0 13 7"
                        fill="none"
                      >
                        <path
                          d="M12 0.879696L7.14818 5.73151C6.7902 6.0895 6.2098 6.0895 5.85182 5.73151L1 0.879695"
                          stroke="white"
                          stroke-width="1.70677"
                          stroke-linecap="round"
                        />
                      </svg>
                    </Button>
                  ) : null}

                  <Nav.Link className="link-nav me-4" id="link-acerca" href="/#acerca">
                    ACERCA DE NOSOTROS
                  </Nav.Link>
                  <Nav.Link className="link-nav me-4" href="/resultados">
                    RESULTADOS
                  </Nav.Link>
                  {/*
                  }
                  <Nav.Link id="link-blog" className="link-nav" href="/#blog">
                    BLOG
                  </Nav.Link>
                {*/}
                </Nav>
                <div className="flex flex-wrap flex-row" style={{ gridGap: "20px" }}>
                  <div className="d-flex">
                    <div className="icon-header me-5 flex flex-row" style={{ gridGap: "7px" }}>
                      <a href="https://www.facebook.com/people/Genea/100094922400180/" target="_blank">
                        <img src={FacebookClaro} alt="" />
                      </a>

                      <a href="https://www.instagram.com/genea.peru/" target="_blank">
                        <img src={VideoClaro} alt="" />
                      </a>

                      <a href="https://www.linkedin.com/in/genea-per%C3%BA-3593a2285/" target="_blank">
                        <img src={InClaro} alt="" />
                      </a>

                      <a
                        href="https://api.whatsapp.com/send?phone=51960645355"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </div>

                  </div>
                  <div className="d-flex">
                    <a className="boton-css-2 py-2 px-4 " target="_blank" href="https://calendly.com/citasgenea/30min">Reserva tu Cita</a>
                    <button className="boton-css-2 py-2 px-4" onClick={handleContactClick}>Contacto</button>
                  </div>
                </div>

              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))
      }
      <Overlay
        show={showPopover}
        target={document.querySelector("#link-planes")}
        placement="bottom"
        container={document.querySelector(".contenedor-header")}
        containerPadding={20}
      >
        <Popover id="popover-contained" className="popover-custom">
          <Popover.Body className="cuerpoopc">
            <span
              style={{ color: "#71CC96", paddingBottom: "2px" }}
              className="tituloplane"
            >
              Lista planes
            </span>
            <div className="principaldi">
              <div className="divsecundario">
                <a href="/Plan/Plus" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Genea Total Plus</p>
                </a>
                {
                  /*
                    <a href="/Plan/Prime" style={{ textDecoration: "none" }}>
                    <p style={{ color: "white" }}>Plan Gen Prime</p>
                  </a>
                  */
                }
                {/* <a href="/Plan/Ultra" style={{ textDecoration: "none" }}>
            <p style={{ color: "white" }}>Plan Gen Ultra</p>
          </a> */}
              </div>
              <div className="divsecundario1">
                {/* <a href="/Plan/Vital" style={{ textDecoration: "none" }}>
            <p style={{ color: "white" }}>Plan Gen Vital</p>
          </a> */}
                {
                  /*
                  <a href="/Plan/Ultra" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen Ultra</p>
                </a>
                <a href="/Plan/Futuro" style={{ textDecoration: "none" }}>
                  <p style={{ color: "white" }}>Plan Gen Futuro</p>
                </a>
                  */
                }
              </div>
            </div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div >
  );
}

export default NavBarGeneral;
