import React from 'react'
import user from '../images/user1.png'

function Bienvenida() {
  const name = localStorage.getItem('name')
  const username = localStorage.getItem('username')
  return (
    <div className=" text-center" >
      <div className="container py-3">
        <img src={user} alt="user" className="img-fluid mb-3" width="200" />
        <p className="fs-5">
          <span className="fw-bold">Nombre: </span>
          {name}
        </p>
        <p className="fs-5">
          <span className="fw-bold">Usuario: </span> {username}
        </p>
      </div>
    </div>
  )
}

export default Bienvenida
