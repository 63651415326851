import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'

function ActualizarUsuario() {
  const { id } = useParams()
  const navigate = useNavigate()
  const [nombre, setNombre] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [validated, setValidated] = useState(false)

  // Obtener los datos del producto a actualizar al cargar el componente
  useEffect(() => {
    // Realizar la petición GET al backend para obtener los datos del producto
    fetch(process.env.REACT_APP_API_URL + `Login.php?consultar=${id}`)
      .then((response) => response.json())
      .then((data) => {
        // Verificar si se encontró el producto
        if (data.id) {
          setNombre(data.nombre)
          setUsername(data.username)
          setPassword(data.password)
        } else {
          alert('No se encontró el producto')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        alert('Error al realizar la petición')
      })
  }, [id])

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault()
      e.stopPropagation()
    }

    setValidated(true)

    // Crear objeto FormData para enviar los datos y la imagen
    const formData = new FormData()
    formData.append('nombre', nombre)
    formData.append('username', username)
    formData.append('password', password)

    // Realizar la petición POST al backend para actualizar el producto
    fetch(process.env.REACT_APP_API_URL + `Login.php?actualizar=${id}`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Mostrar mensaje de éxito o error según la respuesta del backend
        if (data.success) {
          alert('Usuario actualizado correctamente')
          localStorage.setItem('name', nombre)
          localStorage.setItem('username', username)
          localStorage.setItem('password', password)
          navigate('/admin/panel/')
        } else {
          alert('Error al actualizar el producto')
        }
      })
      .catch((error) => {
        console.error('Error:', error)
        alert('Error al realizar la petición')
      })
  }

  return (
    <div className=" insertarArticulo contenedor-dashboard d-flex align-items-center justify-content-center w-100">
      <div className="dashboard-form">
        <div className=" contenerdor-shadow insertarArticuloCard">
          <div className="card-header mb-5" style={{ borderBottom: "1px solid black" }}>
            <h1 className="text-center fw-bold insertarArticuloTitulo">EDITAR USUARIO</h1>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3 mx-3" controlId="validationCustom01">
              <Form.Label>Nombre del usuario</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese nombre del producto"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 mx-3" controlId="validationCustom03">
              <Form.Label>Usario</Form.Label>
              <Form.Control
                type="text"
                className="mb-1"
                placeholder="Usario"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 mx-3" controlId="validationCustom04">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                type="password"
                className="mb-1"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <div className="card-footer">
              <div className="text-center align-middle">
                <div className="d-flex justify-content-center">
                  <Button type="submit" className="boton" style={{border: "#07a34f",padding: "15px 45px",marginTop: "35px",borderRadius: "25px",color: "#fff",fontWeight: "bold"}}>
                    Guardar
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ActualizarUsuario
