import React, { useEffect, useState } from "react";
import "../index.css";
import "../PlanComponent.css";
import Banner_plan05 from "../images/banner/Banner_plan05.jpg";
import bannerMovil5 from "../images/bannerMovil/bannerMovil5.jpg";
import PlanComponent from "./PlanComponent";
import ListaEnfermedad from "./enfermedad";
import iconoPeque01 from "../images/iconos/iconoPeque01.svg";
import iconoPeque02 from "../images/iconos/iconoPeque02.svg";
import iconoPeque03 from "../images/iconos/iconoPeque03.svg";
import iconoPeque04 from "../images/iconos/iconoPeque04.svg";
import iconoPeque05 from "../images/iconos/iconoPeque05.svg";
import iconoPeque06 from "../images/iconos/iconoPeque06.svg";
import Group05 from "../images/logos/Group05.svg";
import Grupo05 from "../images/logos/Grupo05.svg";
import babys from "../images//babys.svg";
import { Button } from "react-bootstrap";
//import { PDFDownloadLink, Document, Page } from 'react-pdf';
import { saveAs } from 'file-saver';
import flecha from "../images/flecha.png";

function PlanPlus() {

    const [Consultar, setConsultar] = useState(window.innerWidth >= 260);
    useEffect(() => {
        const handleResize = () => {
            setConsultar(window.innerWidth >= 260);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setConsultar(window.innerWidth >= 260);
    }, []);

    const [UsarBr, setUsarBr] = useState(window.innerWidth > 620);

    // useEffect para manejar el cambio de tamaño de la ventana
    useEffect(() => {
        const handleResize = () => {
            setUsarBr(window.innerWidth > 620);
        };

        window.addEventListener('resize', handleResize);

        // Función de limpieza para eliminar el event listener cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // useEffect para manejar el cambio inicial al cargar la página
    useEffect(() => {
        setUsarBr(window.innerWidth > 620);
    }, []);

    const enfermedades = () => {
        return (
            <div className="flex flex-wrap flex-column" style={{ justifyContent: "center", alignItems: "center", gridGap: "20px" }}>
                <img src={babys} alt="" className="imagenBabys img-fluid" />
                <Button className="BotonDescarga" style={{
                    color: "#77C093",
                    fontFamily: "Rubik",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontHeight: "700",
                    lineWeight: "normal",
                    borderradius: "17px",
                    border: "1px solid #77C093",
                    background: "#F8FFFB",
                    width: "318px",
                    height: "46px"
                }}
                
                onClick={handleDownloadDirect}
                >Descargar las 183 enfermedades</Button>
                
                
            </div >
            
            
        );
    };

    const descripcion = () => {
        return (
            <div>
                <p style={UsarBr ? null : { textAlign: "justify" }}>
                    <b>71% de hospitalizaciones pediátricas son ocasionadas por enfermedades {UsarBr ? (<br />) : null}
                        de origen genético. </b>
                    <br /><br />
                    Este plan nos permite identificar potenciales enfermedades provocadas por {UsarBr ? (<br />) : null}
                    genes recesivos. Las enfermedades recesivas se heredan a la siguiente {UsarBr ? (<br />) : null}
                    generación, mas no se desarrollan en el portador (padres)
                    <br /><br />
                    <b>Dirigido a: </b>{UsarBr ? (<br />) : null}
                    Parejas con planes reproductivos {UsarBr ? (<br />) : null}
                    Padres con niños entre los 0 a 15 años. {UsarBr ? (<br />) : null}
                </p>
                <div className="flex justify-content-center">
                        <a href="https://api.whatsapp.com/send?phone=51960645355"
                            target="_blank"
                            rel="noopener noreferrer">
                            <Button
                                className={Consultar ? "PlanComponentBoton01" : ""}
                                style={{ backgroundColor: "#18C569", border: "0px" }}
                            >
                                {Consultar ? "Consulta por este plan" : "Consultar"} <img src={flecha} alt="" style={{marginLeft:"6px"}}/>
                            </Button>
                        </a>
                       
                    </div> 
                
                <div className="flex justify-content-center">
                <a href="https://calendly.com/citasgenea/30min/"
                target="_blank"
                rel="noopener noreferrer"
                className='botonfuturo'>
                  Separa cita
                </a>
                </div> 
            </div>
        )
    }

    const styles = {
        miComponente: {
            marginTop: "-25px",
        },
    };

    const pdfPath = '/Enfermedades.pdf'; // Ruta relativa del archivo PDF en la carpeta public

    // Función para descargar el archivo PDF directamente
    const handleDownloadDirect = () => {
        saveAs(pdfPath, 'Enfermedades.pdf');
    };


    return (
        <div id="futuro">
            <PlanComponent
                banner={Banner_plan05}
                titulo={Grupo05}
                enfermedades={enfermedades()}
                logo={Group05}
                descripcion={descripcion()}
                banner01={bannerMovil5}
                linkPlan="/Plan/Futuro/#futuro"
                estilo={styles.miComponente}
            />
        </div>
    );
}

export default PlanPlus;
