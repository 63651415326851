import React from "react";
import "../index.css";

function TextoPoliticaPrivacidad() {
    return (
        <div>
            <p>
                <b className="titulosmo">1. Objetivo</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    La presente política de privacidad de datos personales (en adelante, la “Política de
                    Privacidad”), tiene por finalidad informar cómo MEDICAL CARE SERVICES S.A.C. (en adelante,
                    “GENEA”), identificada con RUC Nº  20553618518, con domicilio en Los Eucaliptos 438, San
                    Juan de Lurigancho, provincia y departamento de Lima, realiza el tratamiento de los datos
                    personales que recopila mediante la navegación y utilización del sitio web www.genea.com.pe
                    que administra (en adelante, “Sitio Web”), el llenado de formularios virtuales y los
                    distintos espacios con los que cuenta el Sitio Web para consignar datos personales de
                    forma virtual. <br />
                    Para estos efectos, haremos referencia al “Titular”, como aquella persona cuyos datos serán
                    recopilados y tratados de acuerdo a los términos establecidos en la presente Política de Privacidad.
                    Únicamente son tratados los datos personales para los cuales se ha obtenido el debido consentimiento,
                    salvo aquellos datos personales necesarios para que GENEA pueda atender las solicitudes y
                    consultas realizadas por el Titular.
                </div>
                <br />{" "}
                <b className="titulosmo">
                    2. Marco normativo
                </b>{" "}
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    Esta Política de Privacidad se encuentra regulada por la legislación peruana y, en
                    particular, por las siguientes normas:
                    •	Ley No. 29733, Ley de Protección de Datos Personales (en adelante, la “Ley”) <br />
                    •	Decreto Supremo No. 003-2013-JUS, que aprueba el Reglamento de la Ley No. 29733
                    (en adelante, el “Reglamento”) <br />
                    •	Directiva de Seguridad de la Información, aprobada por la Resolución Directoral
                    No. 019-2013-JUS/DGPDP.
                </div><br />
                <b className="titulosmo">3. Plazo de conservación</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    Los datos personales proporcionados por el Titular se conservarán en tanto sean
                    necesarios para cumplir con la finalidad para la cual fueron recopilados y/o mientras
                    el Titular no solicite su cancelación conforme a lo regulado por la Ley y normativa aplicable.
                </div><br />
                <b className="titulosmo">4. Banco de datos personales</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    Los datos personales proporcionados se almacenarán en el Banco de Datos de USUARIO, de
                    titularidad de GENEA, inscrito en el Registro Nacional de Protección de Datos Personales a
                    cargo de la Autoridad Nacional de Protección de Datos Personales, en cumplimiento de la Ley y
                    el Reglamento.
                </div><br />

                <b className="titulosmo">
                    5. Finalidad
                </b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    GENEA recopila datos personales de carácter identificativo que aparezcan en los
                    formularios virtuales del Sitio Web y aquellos que se puedan extraer del registro
                    de las interacciones realizadas por los usuarios, tales como nombre y apellido, número
                    de documento de identidad o identificación válida, número de teléfono, domicilio y
                    dirección de e-mail. <br />
                    Estos datos personales serán necesarios para llevar a cabo los siguientes fines: (i)
                    ejecutar la relación contractual entre el Titular y GENEA, (ii) consulta de resultados
                    y reportes históricos de análisis clínicos (iii) contactar a los titulares para atender las consultas que realicen a través del Sitio Web (iv) enviar información sobre los servicios de GENEA que han sido contratados por los titulares, (v) contactar a los titulares para atender las postulaciones que realicen a través del Sitio Web, y (vi) realizar análisis de datos estadísticos anonimizados y perfilamiento que permitan mantener y optimizar el servicio de la Página Web. En caso el Titular no proporcione sus datos, GENEA no podrá realizar las finalidades antes descritas.
                    Adicionalmente, con su autorización, GENEA podrá utilizar los datos personales para las
                    siguientes finalidades: <br />
                    •	Realizar encuestas de satisfacción para mejorar la calidad de los servicios que
                    ofrecemos. <br />
                    •	Enviar comunicaciones referidas a nuevos servicios y promociones. <br />
                    •	Transferir sus datos personales, a nivel nacional, con la finalidad de cumplir con
                    las dos finalidades adicionales antes mencionadas, a las empresas vinculadas y asociadas
                    de GENEA listados en nuestro portal web. <br />
                    En el supuesto que no acepte el tratamiento adicional de sus datos personales, esto no
                    afectará el acceso y uso del servicio contratado.
                </div><br />
                <b className="titulosmo">6. Transferencia y destinatarios</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    Se informa que los datos personales son compartidos con HOSTINGER, INC., cuyos
                    servidores se encuentran en Estados Unidos, en virtud del servicio de hosting y
                    almacenamiento de información en la nube contratado por GENEA con esta empresa. <br />
                    Adicionalmente, si contamos con el consentimiento previo del Titular para finalidades adicionales,
                    los datos podrán ser compartidos con aliados comerciales estratégicos de GENEA, con la finalidad de
                    que puedan publicitar sus servicios y productos. El listado de estas empresas se encuentra
                    disponible en nuestro portal web como Lista de empresas – Transparencia.
                </div> <br />
                <b className="titulosmo">7. Medidas de Seguridad</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    GENEA adopta las medidas de seguridad necesarias para garantizar la protección de la información
                    del Titular a fin de evitar su alteración, pérdida, tratamiento y/o acceso no autorizado,
                    tomando en consideración la naturaleza de la información y los riesgos a los que se encuentran
                    expuestos. Para proteger los datos personales del Titular, cumplimos estrictamente con lo
                    establecido en la Ley y el Reglamento.
                </div>
                <br />
                <b className="titulosmo">8. Divulgacións</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    GENEA se compromete a no divulgar o compartir los datos personales proporcionados, sin que el
                    Titular haya prestado el debido consentimiento para ello, con excepción de los siguientes casos: <br />
                    •	Solicitudes de información de autoridades públicas en ejercicio de sus funciones y el ámbito de
                    sus competencias. <br />
                    •	Solicitudes de información en virtud de órdenes judiciales. <br />
                    •	Solicitudes de información en virtud de disposiciones legales. <br />
                </div>
                <br />
                <b className="titulosmo">9. Ejercicio de derechos ARCO</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">

                    El Titular puede ejercer los derechos de acceso, rectificación, oposición,
                    cancelación, revocación y demás derechos consagrados en la Ley y su Reglamento,
                    mediante una solicitud que puede ser presentada en cualquiera de nuestros
                    establecimientos a nivel nacional detallados en www.genea.com.pe, o remitida a
                    informes@genea.com.pe. <br />
                    Sin perjuicio de lo anterior, GENEA podrá conservar determinada información del
                    Titular que solicita la baja, a fin de que sirva de prueba ante una eventual
                    reclamación contra GENEA. La duración de dicha conservación no será superior al
                    plazo de prescripción legal de dichas responsabilidades. De considerar que no ha
                    sido atendido en el ejercicio de sus derechos, el Titular puede presentar una
                    reclamación ante la Autoridad Nacional de Protección de Datos Personales.
                </div>
                <br />
                <b className="titulosmo">10. Modificaciones de la Política de Privacidad</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    GENEA se reserva expresamente el derecho a modificar, actualizar o completar en
                    cualquier momento la presente Política de Privacidad. Cualquier modificación,
                    actualización o ampliación producida en la presente Política de Privacidad será
                    inmediatamente comunicada a través de la Página Web.
                </div>
                <br />
                <b className="titulosmo">11. Legislación aplicable y resolución de conflictos</b>
                <br />
                <div style={{ marginTop: "10px" }} className="textoacercano">
                    Los términos de la presente Política de Privacidad se rigen y se interpretan de acuerdo con
                    las leyes vigentes en la República del Perú, sujetándose a la competencia y jurisdicción de los
                    Jueces y Tribunales de Lima, Perú.
                </div>
            </p>
        </div>
    );
}

export default TextoPoliticaPrivacidad;
