import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import noticia1 from '../images/noticia1.png'
import noticia2 from '../images/noticia2.png'
import noticia3 from '../images/noticia3.png'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/swiper-bundle.css';

import SwiperCore, { Autoplay, Navigation, Pagination  } from 'swiper';
SwiperCore.use([Autoplay, Navigation, Pagination ]);
const imageUrl = process.env.REACT_APP_API_URL + 'images/'

function Noticias() {

  const [datosCargados, setDatosCargados] = useState(false)
  const [articulos, setArticulos] = useState([])

  useEffect(() => {
    cargardatos()
  }, [])

  const cargardatos = () => {
    fetch(process.env.REACT_APP_API_URL + 'articulos.php')
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        setDatosCargados(true)
        setArticulos(
          datosRespuesta.map((articulo) => ({
            ...articulo,
            contenido: articulo.contenido.replace(/\n/g, '<br>'),
          }))
        )
      })
      .catch(console.log)
  }

  const recortarContenido = (contenido, longitudMaxima) => {
    if (contenido.length <= longitudMaxima) {
      return contenido
    }
    return contenido.slice(0, longitudMaxima) + '...'
  }

  const [momento01, setMomento01] = useState(window.innerWidth >= 1200);
  const [momento02, setMomento02] = useState(window.innerWidth >= 600);
  useEffect(() => {
      const handleResize = () => {
        setMomento01(window.innerWidth >= 1200);
        setMomento02(window.innerWidth >= 600);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setMomento01(window.innerWidth >= 1200);
    setMomento02(window.innerWidth >= 600);

  }, []);
  

  if (!datosCargados) {
    return (
      <div>
        {/* <img src={cargando} alt="" className='blogCarganndoInformación01'/> */}
      </div>
    )
  } else {
    return (
      <div id='blog' className="margin-t">
        <div className="text-center">
          <h2 className="titulo-noticias">BLOG Y NOTICIAS</h2>
        </div>
        <Swiper
          centeredSlides={true}
          slidesPerView={momento01 ? 3 : (momento02 ? 1.7 : 1.1)}
          autoplay={{ delay: 3000 }}
          spaceBetween={50}
          className="mySwiper"
          loop={true}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{ clickable: true }}
          >

          {articulos.map((articulo) => (
            <SwiperSlide lg={3} key={articulo.id} className="blog-articulos px-5 my-5">
              <div className='slider-contenedor'>
                 <div className="d-flex justify-content-center">
                <img
                  src={imageUrl + articulo.imagen}
                  alt="imagen"
                  className="img-fluid mb-3"
                  style={{ maxWidth: '100%', height: '200px' }}
                />
              </div>
              <p className="fw-bold">{articulo.titulo}</p>
              <div dangerouslySetInnerHTML={{ __html: recortarContenido(articulo.contenido, 200) }} />
              <div className="my-3">
                <a href={'blog/articulo/' + articulo.id}>Leer más</a>
              </div>
              </div>
             
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    )
  }

  // return (
  //   <div className="margin-t">
  //     <div className="text-center">
  //       <h2 className="titulo-noticias">BLOG Y NOTICIAS</h2>
  //     </div>
  //     <div className="my-5">
  //       <Container>
  //         <Row>
  //           <Col lg={4}>
  //             <div className="d-flex justify-content-center">
  //               <img src={noticia1} alt="noticia1" className="img-fluid mb-3" />
  //             </div>
  //             <p className="titulo-noticia-2">Avances científicos en secuenciación genómica</p>
  //             <p className="parrafo-noticia">
  //               Explora los últimos avances en tecnologías de secuenciación genómica, como la secuenciación de próxima
  //               generación (NGS) o la secuenciación de una sola molécula, y cómo están revolucionando el campo de la
  //               genómica.
  //             </p>
  //             <p className="parrafo-noticia">2023.03.07</p>
  //           </Col>
  //           <Col lg={4}>
  //             <div className="d-flex justify-content-center">
  //               <img src={noticia2} alt="noticia2" className="img-fluid mb-3" />
  //             </div>
  //             <p className="titulo-noticia-2">Aplicaciones clínicas de la secuenciación genómica</p>
  //             <p className="parrafo-noticia">
  //               Describe cómo la secuenciación genómica se utiliza en el diagnóstico de enfermedades genéticas, la
  //               identificación de biomarcadores y el desarrollo de terapias personalizadas.
  //             </p>
  //             <p className="parrafo-noticia">2023.03.07</p>
  //           </Col>
  //           <Col lg={4}>
  //             <div className="d-flex justify-content-center">
  //               <img src={noticia3} alt="noticia3" className="img-fluid mb-3" />
  //             </div>
  //             <p className="titulo-noticia-2">Futuro de la secuenciación genómica..</p>
  //             <p className="parrafo-noticia">
  //               Explora las tendencias emergentes en la secuenciación genómica, como la secuenciación de larga lectura,
  //               la secuenciación en tiempo real y el potencial de la secuenciación de nanoporos.
  //             </p>
  //             <p className="parrafo-noticia">2023.03.07</p>
  //           </Col>
  //         </Row>
  //       </Container> 
  //     </div>
  //   </div>
  // )
}

export default Noticias
