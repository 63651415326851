import { Container, Nav, Navbar, Form, Button } from 'react-bootstrap'

function Navadmin({ logout }) {
  const id = localStorage.getItem('userId')
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="py-4">
      <Container>
        <Navbar.Brand href="/admin/panel">Administración</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/admin/panel/articulos">Articulos</Nav.Link>
            <Nav.Link href={'/admin/user/edit/' + id}>Usuario</Nav.Link>
            <Nav.Link href="/admin/panel/blog">Blog</Nav.Link>          
          </Nav>
          <Form className="d-flex">
            <Button variant="outline-danger" onClick={logout}>
              Cerrar sesión
            </Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navadmin
