import React, { useEffect, useState } from "react";
import { Container, Nav } from "react-bootstrap";
import logo from "../images/logo-footer.png";
import Group203 from "../images/contacto/Group203.svg";
import Group204 from "../images/contacto/Group204.svg";
import Group205 from "../images/contacto/Group205.svg";
import Group206 from "../images/contacto/Group206.svg";
import flechaArribaMovil from "../images/flechaArribaMovil.png";
import "../index.css"
function Footer(props) {


  // const [UsarBr, setUsarBr] = useState(true);
  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 326) {
  //       setUsarBr(false);
  //     }

  //     /* MUESTRA EL BANNER GRANDE */
  //     else {
  //       setUsarBr(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);



  // const [flecha, setFecha] = useState(true);
  // useEffect(() => {
  //   const handleResize = () => {
  //     /* MUESTRA EL BANNER PEQUEÑO */
  //     if (window.innerWidth <= 290) {
  //       setFecha(false);
  //     }

  //     /* MUESTRA EL BANNER GRANDE */
  //     else {
  //       setFecha(true);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);




  const [UsarBr, setUsarBr] = useState(window.innerWidth >= 326);
  useEffect(() => {
    const handleResize = () => {
      setUsarBr(window.innerWidth >= 326);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setUsarBr(window.innerWidth >= 326);
  }, []);



  const [flecha, setFecha] = useState(window.innerWidth >= 290);
  useEffect(() => {
    const handleResize = () => {
      setFecha(window.innerWidth >= 290);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // useEffect para manejar el cambio inicial al cargar la página
  useEffect(() => {
    setFecha(window.innerWidth >= 290);
  }, []);


  return (
    <div className="margin-t">
      <div className="sep" style={{ marginBottom: "30px" }} key={'sep'}></div>
      <div className="contenedorFooterMovil">
        
        <img src={logo} alt="logo" className="img-fluid" style={{ width: "163px", height: "40.522px", marginBottom: "25px" }} />
        <Nav className="flex flex-nowrap flex-column" >
          <div className="flex flex-wrap flex-row ">
            <Nav.Item className="" style={{ marginRight: "4vh" }}>
              <Nav.Link href="/#acerca" className="link-nav-footerMovil">
                <p className="link-nav-footerMovil">Acerca de genea</p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="" style={{ marginRight: "4vh" }}>
              <Nav.Link href="/" className="link-nav-footerMovil">
                <p className="link-nav-footerMovil">Términos y condiciones </p>
              </Nav.Link>
            </Nav.Item>
          </div>
          <div className="flex flex-wrap flex-row">
            <Nav.Item className="" style={{ marginRight: "4vh" }}>
              <Nav.Link href="/" className="link-nav-footerMovil">
                <p className="link-nav-footerMovil"> Políticas de privacidad</p>
              </Nav.Link>
            </Nav.Item>
          </div>
          <div className="flex flex-wrap flex-row" >
            <Nav.Item className="" style={{ marginRight: "4vh" }}>
              <Nav.Link href="/resultados" className="link-nav-footerMovil">
                <p className="link-nav-footerMovil"> Resultados</p>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="" style={{ marginRight: "4vh" }}>
              <Nav.Link href="/Contacto" className="link-nav-footerMovil">
                <p className="link-nav-footerMovil">Contacto</p>
              </Nav.Link>
            </Nav.Item>
          </div>

        </Nav>
        <Nav className="mt-3 nav-footer flex flex-wrap flex-column">
          <div className="flex flex-wrap flex-column">
            <div>
              <div className="flex flex-wrap flex-row" style={{ gridGap: "4px" }}>
              <svg id="Layer_1" enable-background="new 0 0 30 30" height="34" viewBox="5 0 30 30"
                  width="22" xmlns="http://www.w3.org/2000/svg"><g id="_x31_00"><g><path d="m14.9949951
                  3c-5.1899414 0-9.4299927 4.2399902-9.4299927 9.4400024 0 4.9000244 7.7800293 13.289978 
                  8.6700439 14.2299805.1999513.210022.4799806.3300171.7599488.3300171.2900391 0 
                  .5700073-.1199951.7700195-.3300171.8800049-.9400024 8.6699829-9.3299561 8.6699829-14.2299805.0000001-5.2000122-4.2399902-9.4400024-9.4400024-9.4400024zm.0100098 
                  12.8599854c-2.2199707 0-4.0299683-1.8099976-4.0299683-4.0299683 0-2.2299805 1.8099976-4.0300293 4.0299683-4.0300293 2.210022 0 4.0200195 1.8000488 4.0200195 4.0300293
                  0 2.2199707-1.8099975 4.0299683-4.0200195 4.0299683z" fill="#999999" /></g></g>
                </svg>
                <p className="parrafo-footerMovil">Jr. Horacio Ballón N.° 103 - San Borja</p>
              </div>
              
              <div className="flex flex-wrap flex-row" style={{ gridGap: "10px" }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="35" viewBox="0 0 25 25" fill="none">
                  <g clip-path="url(#clip0_754_1051)">
                    <path d="M12.4996 16.0154C8.91895 16.0154 6.00586 13.1023 6.00586 9.52163C6.00586 5.91357 8.91895 2.97827 12.4996 2.97827C16.0803 2.97827 18.9934 5.91357 18.9934 9.52163C18.9934 13.1023 16.0803 16.0154 12.4996 16.0154ZM12.4996 4.05249C9.51128 4.05249 7.08008 6.50591 7.08008 9.52163C7.08008 12.51 9.51128 14.9412 12.4996 14.9412C15.4879 14.9412 17.9191 12.51 17.9191 9.52163C17.9191 6.50591 15.4879 4.05249 12.4996 4.05249Z" fill="#71CC96" />
                    <path d="M11.7552 12.2926C11.6177 12.2926 11.4803 12.2401 11.3754 12.1353L9.14166 9.90153C8.93195 9.69176 8.93195 9.35167 9.14166 9.14191C9.35143 8.93219 9.69152 8.93219 9.90128 9.14191L11.7552 10.9959L14.8345 7.91656C15.0443 7.70685 15.3843 7.70685 15.5942 7.91656C15.8039 8.12633 15.8039 8.46642 15.5942 8.67618L12.135 12.1353C12.0301 12.2401 11.8926 12.2926 11.7552 12.2926Z" fill="#71CC96" />
                    <path d="M24.9992 10.9814C24.9907 10.8321 24.9178 10.6926 24.8037 10.5957L21.9722 8.15869V0.537109C21.9722 0.240234 21.7314 0 21.4351 0H3.56494C3.26855 0 3.02783 0.240234 3.02783 0.537109V8.15869L0.186523 10.604C0.0683594 10.7056 0 10.8545 0 11.0107V24.4629C0 24.7598 0.240234 25 0.537109 25C0.537109 25 24.46 25 24.4629 25C24.6401 25 24.8149 24.9058 24.9121 24.7573C24.9688 24.6704 25 24.5669 25 24.4629C25 24.4629 25 11.0111 25 11.0107C25 11.0009 24.9997 10.9912 24.9992 10.9814ZM23.6421 11.0132L21.9722 12.4683V9.57617L23.6421 11.0132ZM4.10205 1.07422H20.8979V13.4043L12.5 20.7227L4.10205 13.4048V1.07422ZM3.02783 9.57617V12.4688L1.35791 11.0132L3.02783 9.57617ZM1.07422 12.1914L6.93896 17.3018L1.07422 23.166V12.1914ZM1.83398 23.9258L7.75049 18.0093L12.147 21.8398C12.248 21.9277 12.374 21.9722 12.5 21.9722C12.626 21.9722 12.752 21.9277 12.853 21.8398L17.2495 18.0088L23.166 23.9258H1.83398ZM23.9258 23.166L18.061 17.3018L23.9258 12.1914V23.166Z" fill="#1C3149" />
                  </g>
                  <defs>
                    <clipPath id="clip0_754_1051">
                      <rect width="25" height="25" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="parrafo-footerMovil">informe@genea.com.pe</p>
              </div>
              <div className="flex flex-wrap flex-row" style={{ gridGap: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="35" viewBox="0 0 27 27" fill="none">
                  <g clip-path="url(#clip0_754_1039)">
                    <path d="M18.9806 27C9.59742 26.891 -2.15385 14.8394 0.370794 5.35063C0.882219 2.56885 5.81404 -2.23026 9.02078 1.17683C10.2958 2.52757 11.9377 3.57947 11.8955 5.66819C11.9047 6.98587 11.2754 8.00466 10.3223 8.858C9.24317 9.93721 9.14331 11.6538 10.0901 12.8508C11.2558 14.3261 12.6503 15.7204 14.1257 16.8865C15.3228 17.8332 17.0393 17.7334 18.1185 16.6542L18.5553 16.2175C19.2881 15.4847 20.2658 15.081 21.3084 15.081C23.3946 15.0374 24.4529 16.684 25.7999 17.9558C29.2018 21.1913 24.4197 26.0756 21.626 26.6058C20.8111 26.869 19.924 27 18.9806 27ZM6.36195 1.15938C4.41706 1.08303 1.82319 3.90573 1.50943 5.57503C-0.534163 13.3539 7.60102 22.3046 14.5997 24.953C18.0069 26.1714 21.1317 26.3439 23.7044 24.1802C25.4194 22.712 26.7431 20.5352 24.9797 18.776L23.2413 17.0377C22.2298 15.9824 20.3868 15.9827 19.3755 17.0378L18.9387 17.4745C17.4434 18.9699 15.0648 19.1082 13.4061 17.7964C12.6335 17.1853 11.8816 16.5153 11.1715 15.8051C10.461 15.0946 9.79102 14.3427 9.18021 13.5704C7.86838 11.9118 8.00678 9.53335 9.5021 8.03788L9.93886 7.60111C10.9942 6.59044 10.9939 4.7461 9.93886 3.73532L8.20049 1.99695C7.64077 1.43718 7.03365 1.15938 6.36195 1.15938Z" fill="#1C3149" />
                    <path d="M21.7297 10.7458C21.4621 10.7458 21.2216 10.5595 21.1632 10.2873C20.9272 9.18735 20.3821 8.18548 19.5866 7.39008C18.7792 6.58261 17.7612 6.03402 16.6428 5.80361C16.3291 5.73897 16.1271 5.43228 16.1917 5.11857C16.2564 4.80486 16.5631 4.60292 16.8768 4.66756C18.218 4.94379 19.4386 5.60163 20.4068 6.56985C21.3606 7.52369 22.0144 8.72501 22.2973 10.0441C22.3645 10.3572 22.1651 10.6656 21.8519 10.7328C21.811 10.7415 21.77 10.7458 21.7297 10.7458Z" fill="#71CC96" />
                    <path d="M25.0053 14.0043C24.9674 14.0043 24.929 14.0006 24.8905 13.9928C24.5765 13.9298 24.373 13.6241 24.4361 13.3101C25.0402 10.3015 24.1054 7.21003 21.9356 5.04016C19.7619 2.8664 16.6652 1.9326 13.6521 2.54246C13.3381 2.60631 13.0322 2.403 12.9687 2.08903C12.9051 1.77511 13.1081 1.4691 13.422 1.40557C16.8173 0.71836 20.3065 1.77052 22.7559 4.21993C25.2007 6.66491 26.254 10.1484 25.5733 13.5383C25.518 13.8139 25.2759 14.0043 25.0053 14.0043Z" fill="#71CC96" />
                  </g>
                  <defs>
                    <clipPath id="clip0_754_1039">
                      <rect width="27" height="27" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <p className="parrafo-footerMovil">+51 960 645 355</p>
              </div>
              <p className="parrafo-footerMovil flex"> @Genea, Todos los derechos reservados.</p>
            </div>

            <div className="flex flex-wrap flex-row icon-footer flex flex-wrap flex-row" style={flecha ? { justifyContent: "space-between" } : { justifyContent: "center" }}>
              <div className="flex flex-wrap flex-row" style={{ width: "100%", justifyContent: "end" }}>
                <a href="https://www.linkedin.com/in/genea-per%C3%BA-3593a2285/" target="_blank"
                  rel="">
                  <img style={{ width: "30px" }} src={Group203} alt="" />
                </a>
                <a href="https://www.instagram.com/genea.peru/" target="_blank" rel="">
                  <img style={{ width: "30px" }} src={Group204} alt="" />
                </a>
                <a href="https://www.facebook.com/people/Genea/100094922400180/" target="_blank" rel="">
                  <img style={{ width: "30px" }} src={Group205} alt="" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=51960645355"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img style={{ width: "30px" }} src={Group206} alt="" />
                </a>
              </div>
              {/* <div>
                <a href={props.link}>
                  <img style={{marginLeft:"-5px"
                  }} src={flechaArribaMovil} alt="" />
                </a>
              </div> */}
            </div>
            <iframe
        className="mapa"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3901.4291301794146!2d-76.9968646!3d-12.0827473!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105c632c308ecfb%3A0xe8f0fd6ade15a653!2sJr.%20Horacio%20Ball%C3%B3n%20103%2C%20San%20Borja%2015021!5e0!3m2!1ses-419!2spe!4v1713805530443!5m2!1ses-419!2spe"
        width={400}
        height={300}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
    />
          
            

          </div>


        </Nav>
      </div>
    </div>
  );
}

export default Footer;
